import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = ({
  title,
  description = 'Shecobysheroes is more than a Social Networking Platform. Learn & share about career, cooking, relationships, health, lifestyle and everything else to grow in life.',
  ogImageUrl = 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1542368945og_image.png',
  ogUrl = 'https://app.shecobysheroes.com/',
  canonicalUrl,
  ampUrl,
}) => {
  const pageTitle = title
    ? title + ' | Shecobysheroes'
    : 'shecobysheroes ';
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>

      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={pageTitle}></meta>

      <meta property="og:url" content={ogUrl} />

      <meta property="og:image" content={ogImageUrl} />

      <meta name="twitter:image" content={ogImageUrl} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {ampUrl && <link rel="amphtml" href={ampUrl} />}
    </Helmet>
  );
};

export default MetaData;
