import {
  MODAL_OPENED,
  MODAL_CLOSED,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  OPEN_SEARCH_MODAL,
  CLOSE_SEARCH_MODAL,
} from "../actions/actionTypes";

const initialState = {
  modalOpened: false,
  loginModalOpened: false,
  searchModalOpened: false,
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPENED:
      return {
        ...state,
        modalOpened: true,
      };

    case MODAL_CLOSED:
      return {
        ...state,
        modalOpened: false,
      };

    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpened: true,
      };

    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpened: false,
      };

    case OPEN_SEARCH_MODAL:
      return {
        ...state,
        searchModalOpened: true,
      };

    case CLOSE_SEARCH_MODAL:
      return {
        ...state,
        searchModalOpened: false,
      };

    default:
      return state;
  }
};
