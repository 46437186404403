import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LOGIN_SUCCESS } from "../../actions/actionTypes";
import Loader from "../../components/loader";
import { USER_DATA_KEY_IN_STORAGE } from "../../constants/global";
import { userLogin } from "../../lib/api";

export default () => {
  const search = useLocation().search;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    if (token) {
      fetchData(token);
    } else {
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (token) => {
    try {
      const response = await userLogin({
        token,
        provider: "SHEROES",
        domain: "SHOPTYPE",
      });
      // const response = await userLogin({
      //   token: token,
      //   userType: "coseller",
      //   //domain: "SHOPTYPE",
      // });
      console.log("response:", response);
      //const { data: responseData } = response.data;
      //handlePostAuth(responseData);
      handlePostAuth(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getFirstAndLastName = (name) => {
    let parts = name.split(" ");
    return parts.length > 1
      ? {
          firstName: parts[0],
          lastName: parts[parts.length - 1],
        }
      : {
          firstName: parts[0],
          lastName: "",
        };
  };

  const handlePostAuth = (responseData) => {
    console.log("responseData", responseData);
    const { firstName, lastName } = getFirstAndLastName(responseData.data.name);
    const payload = {
      userToken: responseData.data.token,
      user: {
        emailId: responseData.data.email,
        name: responseData.data.name,
        firstName,
        lastName,
      },
    };

    // const payload = {
    //   userToken: responseData.token,
    //   user: {
    //     emailId: responseData.email ? responseData.email : "",
    //     name: responseData.name ? responseData.name : "",
    //     firstName,
    //     lastName,
    //   },
    // };
    dispatch({
      type: LOGIN_SUCCESS,
      payload,
    });

    localStorage.setItem(USER_DATA_KEY_IN_STORAGE, JSON.stringify(payload));

    history.push("/home");
  };

  return (
    <div style={{ marginTop: "300px" }}>
      <Loader />
    </div>
  );
};
