import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import { validateOtp, getOtp } from "../../lib/userServices";
import { getOtpRequest } from "../../utils/userUtil";
import { userLogin } from "../../lib/api";
import { LOGIN_SUCCESS } from "../../actions/actionTypes";
import { USER_DATA_KEY_IN_STORAGE } from "../../constants/global";
import { useDispatch } from "react-redux";
import Loader from "../../components/loader";

const VerifyOtp = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [counter, setCounter] = useState(60);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  let { mobileNo, emailId, isdCode, activeForm } = "";
  !history.location.state
    ? history.push("/")
    : ({ mobileNo, emailId, isdCode, activeForm } = history.location.state);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  async function handleVerifyOtp() {
    const request = getOtpRequest(activeForm, mobileNo, isdCode, otp, emailId);
    setShowLoader(true);
    setDisabled(true);
    const response = await validateOtp(request);
    console.log("response:", response);
    if (response.status === "FAILED") {
      setErrorMsg(response.error_message);
    }
    //setUserData();
    await fetchData(response.token);
  }
  const fetchData = async (token) => {
    //console.log("response in fetchdata", response);
    try {
      const response = await userLogin({
        token,
        provider: "SHEROES",
        domain: "SHOPTYPE",
      });
      // const response = await userLogin({
      //   token: token,
      //   userType: "coseller",
      //   //domain: "SHOPTYPE",
      // });

      const { data: responseData } = response;
      handlePostAuth(responseData);
      setShowLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getFirstAndLastName = (name) => {
    let parts = name.split(" ");
    return parts.length > 1
      ? {
          firstName: parts[0],
          lastName: parts[parts.length - 1],
        }
      : {
          firstName: parts[0],
          lastName: "",
        };
  };

  const handlePostAuth = (responseData) => {
    console.log("responseData:", responseData);
    const { firstName, lastName } = getFirstAndLastName(responseData.data.name);
    // const firstName = responseData.user_summary.first_name;
    // const lastName = responseData.user_summary.last_name;
    const payload = {
      userToken: responseData.data.token,
      user: {
        emailId: responseData.data.email,
        name: responseData.data.name,
        firstName,
        lastName,
      },
    };
    dispatch({
      type: LOGIN_SUCCESS,
      payload,
    });
    localStorage.setItem(USER_DATA_KEY_IN_STORAGE, JSON.stringify(payload));

    history.push("/home");
  };
  const handleChange = (value) => {
    setOtp(value);
    setDisabled(value.length === 6 ? false : true);
  };

  async function handleGetOtp() {
    const request = activeForm === "mobile" ? mobileNo : emailId;
    await getOtp(activeForm, request);
    setCounter(60);
  }
  const handleFormChange = () => {
    history.push("/mobile-email-form");
  };

  return (
    <React.Fragment>
      <Box m={4}>
        <Typography variant="subtitle2">Verify OTP</Typography>
        <Box mt={1} mb={2}>
          <Typography variant="caption">
            Enter the OTP we just sent to your{" "}
            {activeForm === "mobile"
              ? `${"mobile number " + mobileNo}`
              : `${"Email ID " + emailId}`}
          </Typography>
          <Typography
            variant="overline"
            className={classes.redText}
            onClick={handleFormChange}
          >
            {activeForm === "mobile"
              ? "Change Mobile Number"
              : "Change EmailID"}
          </Typography>
        </Box>

        <OtpInput
          value={otp}
          numInputs={6}
          containerStyle={classes.containerBox}
          inputStyle={classes.textField}
          onChange={handleChange}
        />
        <Box mr={5} mt={3} align="right">
          00:{counter}
          {showLoader && <Loader size="70px" />}
        </Box>
        {errorMsg !== "" && (
          <Box>
            <Typography className={classes.incorrectText}>
              {errorMsg}
            </Typography>
          </Box>
        )}
        {counter === 0 && (
          <Box mt={10} align="center">
            {/* <Typography className={classes.incorrectText}>
              Oops this otp incorrect.
            </Typography> */}
            <Typography variant="body1" display="inline">
              Didn’t receive OTP?
            </Typography>
            <Typography
              variant="body2"
              display="inline"
              className={classes.redText}
              onClick={handleGetOtp}
            >
              Resend OTP
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        variant="contained"
        color="secondary"
        fullWidth={true}
        className={`stickToBottom ${classes.setButton}`}
        size="large"
        disabled={disabled}
        onClick={handleVerifyOtp}
      >
        Verify and continue
      </Button>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    fontSize: "40px",
    border: "none",
    background: "none",
    borderBottom: "2px solid #3c3c3c",
    marginRight: "10px",
    outline: "none",
  },
  redText: {
    color: "#d4574b",
    margin: theme.spacing(0, 0, 0, 1),
  },
  containerBox: {
    justifyContent: "center",
  },
  incorrectText: {
    color: "#f79400",
  },
  setButton: {
    borderRadius: "inherit",
  },
}));

export default VerifyOtp;
