import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal, Backdrop, Fade } from "@material-ui/core";
import VideoDetail from "./videoDetail";

const VideoModal = ({ showModal, setShowModal, video }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <Box className={classes.paper} borderRadius={0}>
            <VideoDetail video={video} />
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};
export default VideoModal;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "#3c3c3c",
    padding: "10px",
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
}));
