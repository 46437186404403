import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
// import ProductCategoriesSection from "../../components/product-categories-section/ProductCategoriesSection";
import ProductCategoriesCarousel from "../../components/product-categories-section/ProductCategoriesCarousel";
import HomeCarousal from "../../components/home-carousal/HomeCarousal";

import "./Home.scss";
import SellSheco from "../../components/sell-sheco/SellSheco";
import FAQ from "../../components/FAQ/FAQ";
import { useDispatch, useSelector } from "react-redux";
import TopNavbar from "../../components/top-navbar/TopNavbar";
import {
  FETCHED_HOME_DATA,
  FETCH_HOME_PAGE_DATA,
} from "../../actions/actionTypes";
import { getSections } from "../../lib/api";
import ArticlesCarousel from "../articles/articlesCarousel";
import VideoCarousel from "../videos/videoCarousel";
import RedirectBanner from "../../components/home-elements/RedirectBanner"
import ProductVendorBanner from "../../components/home-elements/ProductVendorBanner"
import ProductVendorCarousel from "../../components/home-elements/ProductVendorCarousel"
import ProductTagCarousel from "../../components/home-elements/ProductTagCarousel"
import ProductCategoriesList from "../../components/product-categories-section/ProductCategoriesList";
import * as sampledata from "../../testdata.json";

export default () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const stagingdata=sampledata.default.elements;

  const section = useSelector((state) => state.home.sections);
  useEffect(() => {

    dispatch({ type: FETCH_HOME_PAGE_DATA });

    getSections().then((res) => {
      let temp = [];
      if (res.data[0]) {
        res.data[0].collections.forEach((element) => {
          temp.push({ key: element.slug, value: element });
        });
      }
      dispatch({
        type: FETCHED_HOME_DATA,
        payload: temp,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getFeatures = (slugkey) => section;

  console.log(getFeatures());
  return (
    <div>
      {isLoggedIn ? (
        <div>
          <TopNavbar />
        </div>
      ) : (
        ""
      )}
      <div>
        
        <HomeCarousal />
      </div>
      {isLoggedIn ? (
        ""
      ) : (
        <div>
          
          <SellSheco />
        </div>
      )}

      <div>
        {
          stagingdata.map((item, index) => (
            <div key = {index}>
            
            {item.type ==='productCategoryCarousel' && item.isActive==='True'?
            <ProductCategoriesCarousel isLoggedIn={isLoggedIn} /> : ''}

            {item.type ==='productVendorBanner' && item.isActive==='True' ?
            <ProductVendorBanner details={item}/> : ''}

            {item.type ==='productTagCarousel' && item.isActive==='True' ?
            <ProductTagCarousel details={item}/> : ''}

            {item.type ==='productVendorCarousel' && item.isActive==='True' ?
            <ProductVendorCarousel details={item}/> : ''}

            {item.type ==='productCategoryList' && item.isActive==='True' ?
            <ProductCategoriesList isLoggedIn={isLoggedIn} /> : ''}

            {item.type ==='redirectBanner' && item.isActive==='True' ?
            <RedirectBanner details={item}/> : ''}
            </div>
        ))
        }
      

        <ArticlesCarousel />
      </div>
      <div>
        <VideoCarousel />
      </div>
      <div>
        <FAQ />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
