import { FETCH_HOME_PAGE_DATA } from "../actions/actionTypes";
import { FETCHED_HOME_DATA } from "../actions/actionTypes";

const initialState = {
  sections: [],
  isFetching: false,
}

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {

    // Fetch data for home page
    case FETCH_HOME_PAGE_DATA:
      return {
        ...state,
        isFetching: true,
      }
    case FETCHED_HOME_DATA:
      return {
        ...state,
        sections: action.payload,
        isFetching: false,
      }

    default:
      return state
  }
}