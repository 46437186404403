import React, { Component } from "react";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
const styles = {
    topImageContainer: {
        position: "relative",
        background: "#a9a9a966",
        height: "30vh",
        padding: "0px 20p",
        marginLeft: "22px",
        marginRight: "22px",
        marginTop: "80px",
      },
      topImgLeftRightIconContainer: {
        position: "absolute",
        top: "20%",
        width: "100%",
        padding: "0px 8px",
        display: "flex",
        justifyContent: "space-between",
      },
      topImgBottomImgContainer: {
        position: "absolute",
        bottom: "0px",
        height: "12vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0px 16px",
        overflowX: "scroll",
      },
      topImgBottomChildImg: {
        width: "14vw",
        background: "white",
        height: "8vh",
        borderRadius: "4px",
        margin: "4px",
        objectFit: "contain",
      },
  };
class CarousalImages extends Component{
    constructor(props){
        super(props) 
        this.state = {
            imageActiveStep: props.imageActiveStep,    
            productDetail: props.productDetail,
        }
      };
      handleNextImgActiveStep = () => {
        this.setState((state) => ({
          imageActiveStep: state.imageActiveStep + 1,
        }));
      };
    
      handleBackImgActiveStep = () => {
        this.setState((state) => ({
          imageActiveStep: state.imageActiveStep - 1,
        }));
      };
    render(){
            const { classes } = this.props;
            const {
                productDetail,
                imageActiveStep,
              } = this.state;
              let carousalImages = productDetail.product
              ? [productDetail.product.primaryImageSrc]
              : [];
              if (
                productDetail.product &&
                productDetail.product.secondaryImageSrc
              ) {
                carousalImages = [
                  ...carousalImages,
                  ...productDetail.product.secondaryImageSrc,
                ];
              }   
        return(
            <div>
             {carousalImages && carousalImages.length && (
              <div className={classes.topImageContainer}>
                <img
                  src={carousalImages[imageActiveStep].imageSrc}
                  id="img"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt={productDetail.product.title}
                />
                <div className={classes.topImgLeftRightIconContainer}>
                  <KeyboardArrowLeft
                    onClick={(e) => {
                      e.preventDefault();
                      if (imageActiveStep > 0) {
                        this.handleBackImgActiveStep();
                      }
                    }}
                    style={{ width: "2em", height: "2em", color: "white" }}
                  />
                  <KeyboardArrowRight
                    onClick={(e) => {
                      e.preventDefault();
                      if (imageActiveStep < carousalImages.length - 1) {
                        this.handleNextImgActiveStep();
                      }
                    }}
                    style={{ width: "2em", height: "2em", color: "white" }}
                  />
                </div>
                {/* <div className={classes.topImgBottomImgContainer}>
                  {carousalImages.map((step, index) => (
                    <img
                      className={classes.topImgBottomChildImg}
                      src={step.imageSrc}
                      alt={step.id}
                      key={step.id}
                    />
                  ))}
                </div> */}
              </div>
                )}
            </div>
        )
    }
}export default  withStyles(styles) (CarousalImages);