import React from "react";
import "./Communities.scss";
import { communityData } from "../../data/communityData";
import CommunityCard from "../../components/community-card/CommunityCard";

const Communities = () => {
  return (
    <div className="container communities-container">
      <div className="pt-3 title-section mb-3">
        <span className="bold">Discover Communities</span>
        <span className="uppercase coral-red">View All</span>
      </div>

      {communityData.map((item, index) => (
        <CommunityCard
          key={index}
          id={item.id}
          title={item.title}
          subTitle={item.subTitle}
          numMembers={item.numMembers}
          introText={item.introText}
        />
      ))}
    </div>
  );
};

export default Communities;
