export const USER_LOADED = "USER_LOADED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAIL = "SIGNOUT_FAIL";

export const MODAL_OPENED = "MODAL_OPENED";
export const MODAL_CLOSED = "MODAL_CLOSED";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";
export const OPEN_SEARCH_MODAL = "OPEN_SEARCH_MODAL";
export const CLOSE_SEARCH_MODAL = "CLOSE_SEARCH_MODAL";

export const FETCH_HOME_PAGE_DATA = "FETCH_HOME_PAGE_DATA";
export const FETCHED_HOME_DATA = "FETCHED_HOME_DATA";

export const FETCH_PRODUCT_PAGE_DATA = "FETCH_PRODUCT_PAGE_DATA";
export const FETCHED_PRODUCT_DATA = "FETCHED_PRODUCT_DATA";

export const FETCHING_PRODUCT_CATEGORIES = "FETCHING_PRODUCT_CATEGORIES";
export const FETCHED_PRODUCT_CATEGORIES = "FETCHED_PRODUCT_CATEGORIES";

export const FETCHED_PRODUCTS_SEARCH_RESULTS =
  "FETCHED_PRODUCTS_SEARCH_RESULTS";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const CLEAR_SEARCH_QUERY = "CLEAR_SEARCH_QUERY";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const DEVICE_ID_LOADED = "DEVICE_ID_LOADED";
