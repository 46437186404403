import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core";
import Loader from "../loader";
// import { MEDIA_PROXY_URL } from "../../constants/global";
// import { allSettled } from "../../helpers/utils";

const styles = {
  shopCardContainer: {
    // padding: "10px 15px",
    paddingTop: "10px",
    width: "100%",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  imgStyle1: {
    height: "3vh",
    width: "auto",
    cursor: "pointer",
  },
};

const ProductCard = ({
  product,
  classes,
  isWishlisted,
  addProductToMyShop,
  removeProductFromMyShop,
}) => {
  // console.log('product ', product)
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [imageSrc] = useState(product.shoptypeData? product.shoptypeData.primaryImageSrc.imageSrc : product.primaryImageSrc.imageSrc);
  // const [imageTitle] = useState(`*${product.title}* - ${product.description}`);
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    setImgLoading(true);
  }, [product]);

  // let imgURL =
  //   "whatsapp://send?text=" + imageTitle + " " + encodeURIComponent(imageSrc);

  const addToMyShop = (event, action) => {
    event.stopPropagation();
    setAnchorEl(null);

    switch (action) {
      case "add": {
        addProductToMyShop(product);
        break;
      }
      case "remove": {
        removeProductFromMyShop(product);
        break;
      }
      default:
        break;
    }
  };

  // const shareSubmit = async (e) => {
  //   // console.log('pro',product);
  //   const  shoptypeData  = product;
  //   const images =
  //     shoptypeData.secondaryImageSrc && shoptypeData.secondaryImageSrc.length
  //       ? [shoptypeData.primaryImageSrc, ...shoptypeData.secondaryImageSrc]
  //       : [shoptypeData.primaryImageSrc];
  //   e.stopPropagation();

  //   // Image List send
  //   let imageBlobs = await allSettled(
  //     images.map(async (item) => {
  //       let response = await fetch(
  //         MEDIA_PROXY_URL + item.imageSrc
  //       );
  //       return response.blob();
  //     })
  //   );

  //   let files = [];
  //   imageBlobs.forEach((response, index) => {
  //     if (response.status === "fulfilled") {
  //       files.push(
  //         new File([response.value], `share.jpg`, {
  //           type: response.value.type,
  //         })
  //       );
  //     }
  //   });

  //   // console.log(files);

  //   // Send only the single Image
  //   // const response = await fetch(imageSrc);
  //   // const blob = await response.blob();
  //   // const file = new File([blob], `share.jpg`, {
  //   //   type: blob.type,
  //   // });

  //   if (navigator.canShare && navigator.canShare({ files: files })) {
  //     navigator
  //       .share({
  //         text: imageTitle,
  //         title: "Check this out!",
  //         files: files,
  //       })
  //       .then(() => console.log("Successful share"))
  //       .catch((error) => console.log("Error in sharing", error));
  //   } else {
  //     console.log(`system does not support sharing files.`);
  //     window.location.href = imgURL;
  //   }
  // };

  const handleImageLoaded = () => {
    setImgLoading(false);
  };

  return (
    <div
      className={classes.shopCardContainer}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/products/${product.shoptypeData ? product.shoptypeData.id :product.id }`);
      }}
    >
      <Paper elevation={1} style={{ marginTop: "8px" }}>
        <div
          style={{
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{ display: imgLoading ? "block" : "none", marginTop: "25%" }}
          >
            <Loader size={"60px"} />
          </div>
          <img
            src={product.shoptypeData ? product.shoptypeData.primaryImageSrc.imageSrc : product.primaryImageSrc.imageSrc}
            alt={product.title}
            onLoad={handleImageLoaded}
            style={{
              height: "100%",
              display: imgLoading ? "none" : "block",
            }}
          />
          <MoreVertIcon
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              borderRadius: "4px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={addToMyShop}
          >
            <MenuItem
              key={product.slug}
              onClick={(event) =>
                addToMyShop(event, isWishlisted ? "remove" : "add")
              }
            >
              {isWishlisted ? "Remove from my shop" : "Add to my shop"}
            </MenuItem>
          </Menu>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "14px 14px 0px 14px",
            }}
          >
            <span
              style={{
                fontSize: "2.2vh",
                fontWeight: "bold",
                color: "#4A4A4A",
              }}
            >
              {product.title}
            </span>
            <span style={{ color: "red" }}>
              {product.currency}{" "}
              <span style={{ textDecoration: "line-through" }}>
                {product.shoptypeData ? product.shoptypeData.variants && product.shoptypeData.variants[0].price : product.variants && product.variants[0].price}
              </span>{" "}
              {product.shoptypeData ? product.shoptypeData.variants && product.shoptypeData.variants[0].discountedPrice : product.variants && product.variants[0].discountedPrice}
            </span>

            <div className="post ">
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {product.description}
              </p>
              <span>
                <span className="more-link"></span>
              </span>
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "0",
                  backgroundColor: "#ffffff",
                  textAlign: "right",
                  marginRight: "22px",
                  fontWeight: "bold",
                  color: "red",
                  marginBottom: "6px",
                  fontSize: "16px",
                }}
              >
                See More
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "6vh",
              background: "#F7F7F7",
              width: "90%",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
            }}
          >
            <img
              src={require("../../assets/images/Whatsapp-Logo.svg")}
              alt="img"
              className={classes.imgStyle1}
            />
            <span
              style={{
                fontWeight: "bold",
                fontSize: "2vh",
                marginLeft: "12px",
                color: "#4A4A4A",
              }}
            >
              <span
                onClick={(e) => shareSubmit(e)}
                style={{
                  fontWeight: "bold",
                  fontSize: "2vh",
                  marginLeft: "12px",
                  color: "#4A4A4A",
                }}
              >
                Share on whatsapp
              </span>
            </span>
          </div>
        </div> */}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ProductCard);
