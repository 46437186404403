module.exports = {
  appBarColor: "#F4F4F4",
  black: "#2D2D2D",
  blue: "#50ADEE",
  lightBlue1: "#2196F3",
  darkBlue: "#4C74F2",
  lightYellow: "#C4A33B",
  // -------------ACTUAL COLOR CODE ----------
  white: "#FFFFFF",
  bgHeaderOverLay: "#F7F7F7",
  gold: "#EDD17A",
  royalBlue: "#4C74F2",
  lightBlue: "#50ADEE",
  metallicGold: "#DEBC50",
  darkGrey: "#373737",
  mediumGrey: "#696969",
  lightGrey: "#A9A9A9",
  darkRed: "#ff4343"
};
