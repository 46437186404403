import React from "react";
import CustomCarousel from "../videos/customCarousel";
import useReactRouter from "use-react-router";

const ArticlesCarousel = () => {
  const { history } = useReactRouter();
  const listOfArticles = [
    {
      name: "Online Sellers Can’t Sell Online Without These 4 Things (That Nobody’s Talking About)",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16119065521611906552.png?tr=w-341",
      slug: "/articles/online-selling-skills/MTY2NTI=",
      source: "sheroes",
    },
    {
      name: "Top 10 Customer Service Tips For Excellent Customer Support After The Sale",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16100905971610090597.png?tr=w-341",
      slug: "/articles/customer-support/MTY2NDc=",
      source: "sheroes",
    },
    {
      name: "5 Ways To Learn Everything About Your Product And Brand Before You Sell",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16098451531609845153.png?tr=w-341",
      slug: "/articles/sell-products/MTY2NDY=",
      source: "sheroes",
    },
    {
      name: "5 Sales Closing Techniques To Help You Close The Sale Faster",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16085485181608548518.png?tr=w-520",
      slug: "/articles/closing-techniques/MTY2NDM=",
      source: "sheroes",
    },
    {
      name: "7 Social Selling Best Practices To Be A Good Seller Online",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16079322851607932285.png?tr=w-520",
      slug: "/articles/social-selling-best-practices/MTY2NDE=",
      source: "sheroes",
    },
    {
      name: "Social Selling - Part 1: How To Sell On Pinterest",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16015324461601532446.png?tr=w-520",
      slug: "/articles/how-to-sell-on-pinterest/MTY2MzI=",
      source: "sheroes",
    },
    {
      name: "Social Selling – Part 2: How To Sell On Facebook",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16025719461602571946.png?tr=w-520",
      slug: "/articles/social-selling-facebook/MTY2MzQ=",
      source: "sheroes",
    },
    {
      name: "Social Selling – Part 3: How To Sell On WhatsApp",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16069774141606977414.png?tr=w-520",
      slug: "/articles/whatsapp-selling/MTY2Mzg=",
      source: "sheroes",
    },
    {
      name: "Social Selling – Part 4: Instagram Marketing In 2021",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16075092431607509243.png?tr=w-520",
      slug: "/articles/instagram-marketing/MTY2NDA=",
      source: "sheroes",
    },
    {
      name: "6 Steps To Nurture Leads And Get More Sales",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/15971277981597127798.png?tr=w-520",
      slug: "/articles/lead-nurturing-best-practices-strategy-and-consumer-customer-buying-process/MTY2MTg=",
      source: "sheroes",
    },
    {
      name: "10 Secrets Of Successful SHECO Entrepreneurs",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/15982749501598274950.png?tr=w-520",
      slug: "/articles/success-secrets-and-tips-for-sheco-business/MTY2MjI=",
      source: "sheroes",
    },
    {
      name: "Family Gift Ideas For The Festive Season In India",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16056031531605603153.png?tr=w-520",
      slug: "/articles/family-gift-ideas/MTY2Mzc=",
      source: "sheroes",
    },
    {
      name: "Know, Like and Trust (KLT) - The Key To Creating Happy Customers",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/15948021381594802138.png?tr=w-520",
      slug: "/articles/happy-customer-feedback-and-how-to-satisfy-customers/MTY2MTM=",
      source: "sheroes",
    },
    {
      name: "The 4 Mindset Shifts You Need To Achieve A Winner Mindset",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/15997205741599720574.png?tr=w-520",
      slug: "/articles/winner-mindset/MTY2Mjk=",
      source: "sheroes",
    },
    {
      name: "Get To Know The Winners Of SHECO’s #Top40 Challenge",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16034319711603431971.png?tr=w-520",
      slug: "/articles/sheco-top40-sep2020/MTY2MzU=",
      source: "sheroes",
    },
    {
      name: "Secrets Of Successful SHECO Entrepreneurs: Amita Chauhan",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16015330041601533004.png?tr=w-520",
      slug: "/articles/secrets-of-successful-sheco-entrepreneurs-amita-chauhan/MTY2MzM=",
      source: "sheroes",
    },
    {
      name: "Secrets Of Successful SHECO Entrepreneurs: Pranavi Gupta",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/15997237151599723715.png?tr=w-520",
      slug: "/articles/secrets-of-successful-sheco-entrepreneurs-pranavi-gupta/MTY2Mjg=",
      source: "sheroes",
    },
    {
      name: "Amita Chauhan: SHECO Partner Of The Month For July 2020",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/15972291781597229178.png?tr=w-520",
      slug: "/articles/amita-chauhan-sheco-partner-of-the-month-for-july-2020/MTY2MjA=",
      source: "sheroes",
    },
    {
      name: "What Zero-Investment Business Ideas For Housewives Can I Start From Home?",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16127864741612786474.png?tr=w-520",
      slug: "/articles/zero-investment-business-ideas/MTY2NTQ=",
      source: "sheroes",
    },
    {
      name: "How To Make PAN Card Online Free",
      image:
        "https://img.sheroes.in/img/uploads/article/high_res/16133924091613392409.png?tr=w-520",
      slug: "/articles/how-to-make-pan-card-online/MTY2NTk=",
      source: "sheroes",
    },
    {
      name: "SHECO Partners Share How To Make Money From Mobile Phones",
      image:
        "https://img.sheroes.in/img/uploads/sheroes/global/page__202105180833275320.png?tr=w-520",
      slug: "https://www.naaree.com/sheco-make-money-from-mobile/",
      source: "other",
    },
    {
      name: "21 Pandemic-Proof Work-From-Home Business Ideas For Women",
      image:
        "https://img.sheroes.in/img/uploads/sheroes/global/page__202105191128099126.jpeg",
      slug: "https://www.naaree.com/business-ideas-for-women/",
      source: "other",
    },
  ];

  function handleClick(slug, id, source) {
    if (source === "sheroes") {
      history.push(slug, { id });
    } else {
      window.open(slug, "_blank");
    }
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "#F7F7F7",
          paddingTop: "25px",
        }}
        className="p-4 section-title gray29"
      >
        SHECO Blogs
      </div>
      <CustomCarousel
        handleClick={handleClick}
        type="article"
        items={listOfArticles}
      />
    </div>
  );
};

export default ArticlesCarousel;
