import React from "react";
import { NavLink } from "react-router-dom";
import "./TopNavbar.scss";

const TopNavbar = () => {
  return (
    <div className="top-navbar">
      <div className="row nav-link-bar">
        <NavLink 
          to="/home" 
          className="col-4 bold navlink-border-right p5 left-col nav-link" 
          activeClassName="active-link"
          exact
        >
          Home
        </NavLink>
        <NavLink
          to="/products"
          className="col-4 bold navlink-border-right p5 nav-link"
          activeClassName="active-link"
          exact
        >
          Products
        </NavLink>
        <NavLink
          to="/my-shop"
          className="col-4 bold p5 right-col nav-link"
          activeClassName="active-link"
          exact
        >
          My Shop
        </NavLink>
      </div>
    </div>
  );
};

export default TopNavbar;
