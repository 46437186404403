import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { homeReducer } from "./homeReducer";
import { modalReducer } from "./modalReducer";
import { productReducer } from "./productReducer";

export default combineReducers({
  auth: authReducer,
  modal: modalReducer,
  home: homeReducer,
  products: productReducer,
});
