// import React from "react";
// import { useSelector } from "react-redux";
// import Footer from "../../components/footer/Footer";
// import TopNavbar from "../../components/top-navbar/TopNavbar";
// export default () => {
//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
//   console.log("isLoggedIn:", isLoggedIn);
//   return (
//     <div className="mt-5">
//       <br />
//       <h2>Login</h2>
//       <div>
//         <Footer />
//       </div>
//     </div>
//   );
// };

import React from "react";
//import { useSelector } from "react-redux";
import Footer from "../../components/footer/Footer";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const SignIn = () => {
  const classes = useStyles();
  let state = {
    data: { username: "", password: "" },
    errors: {},
  };
  const handleChange = ({ currentTarget: input }) => {
    const data = { ...state.data };
    data[input.name] = input.value;
    //this.setState({ data });
  };

  const handleSubmit = () => {
    doSubmit();
  };
  const doSubmit = async () => {
    try {
      const { data } = this.state;
      console.log("data", data);
      //await auth.login(data.username, data.password);
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        const errors = { ...this.state.errors };
        errors.password = "Username or password is invalid";
        this.setState({ errors });
      }
    }
  };
  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          {/* <Copyright /> */}
          <Footer />
        </Box>
      </Container>
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default SignIn;
// export default function SignIn() {
//   const classes = useStyles();
//   function handleSubmit(e) {
//     e.preventDefault();

//     this.doSubmit();
//   }
//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
// }
