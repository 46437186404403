import React, { useState, useEffect } from "react";
import Fab from "@material-ui/core/Fab";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const MenuItemComponent = ({
  menuValues,
  selectedFilterValue,
  filterOptionClicked,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(selectedFilterValue);

  useEffect(() => {
    setSelectedValue(selectedFilterValue);
  }, [selectedFilterValue]);

  const handleClick = (e) => {
    setAnchorEl(e.target);
  };

  const handleClose = (itemValue) => {
    if (itemValue && typeof itemValue == "string") {
      setSelectedValue(itemValue);
      filterOptionClicked(itemValue);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Fab
        variant="extended"
        style={{
          width: "100%",
          height: "34px",
          backgroundColor: "#ff4343",
          color: "#fff",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "smaller",
        }}
        onClick={handleClick}
      >
        <span style={{ fontSize: "smaller" }}>{selectedValue}</span>
        <ArrowDropDown className="down-arrow-icon" />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuValues.map((itemValue, index) => (
          <MenuItem key={index} onClick={() => handleClose(itemValue)}>
            {itemValue}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuItemComponent;
