import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GridList, GridListTile, GridListTileBar } from "@material-ui/core";
const CustomCarousel = ({ items, type, handleClick }) => {
  const classes = useStyles();

  return (
    <div>
      <GridList className={classes.gridList} cols={1.5}>
        {items &&
          items.map((item, index) => (
            <GridListTile
              key={`${index}`}
              className={classes.gridTilteSet}
              onClick={() => handleClick(type === "video" ? item : item.slug)}
            >
              {type === "video" && (
                <img
                  src="https://go.sheroes.com/public/go/static/images/play-btn.svg"
                  width="40px"
                  alt="play-button"
                  className={classes.playButton}
                />
              )}
              <img
                src={item.image}
                alt={item.logoAltText}
                className={classes.imgSet}
              />

              <GridListTileBar
                title={item.name}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
              />
            </GridListTile>
          ))}
      </GridList>
    </div>
  );
};

export default CustomCarousel;

const useStyles = makeStyles(() => ({
  playButton: {
    height: "40px",
    position: "absolute",
    zIndex: "100",
    top: "40%",
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    height: "180px",
  },
  title: {
    color: "white",
    fontSize: "15px",
    fontWeight: "600",
    lineHeight: "20px",
    whiteSpace: "inherit",
  },
  gridTilteSet: {
    height: "auto!important",
    padding: "0px!important",
    textAlign: "center",
    textDecoration: "none",
    marginRight: "10px",
  },
  imgSet: {
    top: 0,
    height: "100%",
    width: "100%",
    transform: "inherit",
    left: "inherit",
    border: " 1px solid #f1f1f1",
  },
  titleBar: {
    display: "inherit",
    position: "absolute",

    height: "42px",
    color: "white",
    background: "linear-gradient(to bottom,rgba(255,0,0,0),#212020)",
  },
}));
