import React from "react";
import "./SellSheco.scss";

export default () => {
  const features = [
    { id: "1", title: "Work from home with zero investment" },
    { id: "2", title: "Earn High Margines" },
    { id: "3", title: "Get exclusive shop on your profile" },
    { id: "4", title: "Chats directly with chats and customers" },
    { id: "5", title: "Access to product training and learning tools" },
    { id: "6", title: "Full and constant support" },
  ];
  return (
    <div className="categories-section pt25 pb25">
      <div
        className="section-title white mb20"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Sheco for YOU!
      </div>
      <div className="list-style">
        {features.map((item) => (
          <div className="list-item" key={item.id}>
            <img src={require("../../assets/images/female-sign.svg")} alt="" />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
