import {
  USER_LOADED,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  SIGNOUT_SUCCESS,
  DEVICE_ID_LOADED,
} from "../actions/actionTypes";

const initialState = {
  isLoggedIn: false,
  userToken: "",
  user: {
    emailId: "",
    firstName: "",
    lastName: "",
    name: "",
  },
  deviceId: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isLoggedIn: true,
        userToken: action.payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userToken: action.payload.userToken,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userToken: action.payload,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        userToken: "",
      };
    case DEVICE_ID_LOADED:
      return {
        ...state,
        deviceId: action.payload,
      };
    default:
      return state;
  }
};
