import React, { useState } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
//import CustomButton from "../common/customButton";
import MobileForm from "../login/mobileForm";
import EmailForm from "../login/emailForm";
import { getOtp } from "../../lib/userServices";
import useReactRouter from "use-react-router";
import { ISD_CODE } from "../../constants/global";

const MobileEmailForm = () => {
  //const classes = useStyles();
  const { history } = useReactRouter();
  const [activeForm, setActiveForm] = useState("mobile");
  const [activeButtonText, setActiveButtonText] = useState("Or Use Email ID");
  const [disabled, setDisabled] = useState(true);
  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [message, setMessage] = useState("");

  const handleChangeForm = () => {
    setActiveForm(activeForm === "mobile" ? "email" : "mobile");
    setActiveButtonText(
      activeForm === "mobile" ? "Or Use Mobile No" : "Or Use Email ID"
    );
    if (activeForm === "mobile") {
      setMobileNo("");
    } else {
      setEmailId("");
    }
  };
  const handleOnChange = (type, event) => {
    const value = event.target.value;
    if (type === "mobile") {
      const regex = /[0-9]+/g;
      if (value === "" || regex.test(value)) {
        setMobileNo(value);
        if (value.length === 10 && event.key === "Enter") {
          handleGetOtp();
        }
      }
      setDisabled(value.length === 10 ? false : true);
    } else {
      setEmailId(value);
      setDisabled(false);
      if (event.key === "Enter") {
        handleGetOtp();
      }
    }
  };
  async function handleGetOtp() {
    const request = activeForm === "mobile" ? mobileNo : emailId;
    const { data } = await getOtp(activeForm, request);
    const isdCode = ISD_CODE;
    if (data && data.status === "SUCCESS") {
      data.message && setMessage(data.message);
      history.push(
        { pathname: "/verify-otp" },
        { mobileNo, emailId, isdCode, activeForm }
      );
    } else {
      //data && setErrorMessage(data.error_message);
    }
  }

  return (
    <Box style={{ margin: "80px 14px 11px 13px" }}>
      {activeForm === "mobile" ? (
        <MobileForm handleOnChange={handleOnChange} mobileNo={mobileNo} />
      ) : (
        <EmailForm handleOnChange={handleOnChange} emailId={emailId} />
      )}

      <Typography
        color="secondary"
        variant="h6"
        align="right"
        onClick={handleChangeForm}
      >
        {activeButtonText}
      </Typography>
      <Typography>{message && message}</Typography>
      <Button
        variant="contained"
        color="secondary"
        fullWidth={true}
        //className={`stickToBottom ${classes.setButton}`}
        size="large"
        disabled={disabled}
        onClick={handleGetOtp}
      >
        Send OTP
      </Button>
    </Box>
  );
};
// const useStyles = makeStyles((theme) => ({
//   textField: {
//     width: "100%",
//   },
//   setButton: {
//     borderRadius: "inherit",
//   },
// }));
export default MobileEmailForm;
