import React, { Component} from "react";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Fab from "@material-ui/core/Fab";
import SwipeableViews from "react-swipeable-views";
import InfiniteScroll from "react-infinite-scroll-component";
import { autoPlay } from "react-swipeable-views-utils";
import { toast } from "react-toastify";
import { darkRed, white, lightGrey } from "../../colors/color";
import TopNavbar from "../../components/top-navbar/TopNavbar";
import {
  addProductToMyShop,
  getProductCollections,
  getProducts, getVendorDetails,
  getVendorsList
} from "../../lib/api";
import ProductCard from "../../components/product-card";
import Loader from "../../components/loader";


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
  shopCardContainer: {
    padding: "12px 22px",
    width: "100%",
  },
  imgStyle1: {
    height: "3vh",
    width: "auto",
    cursor: "pointer",
  },
  productHomeContainer: {
    height: window.innerHeight - 65,
    marginTop: "18vh",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginLeft: "15px",
    marginRight: "15px",
  },
  imgStyle: {
    height: "21vh",
    width: "100%",
    objectFit: "contain",
    borderRadius: "10px",
  },
  mobileStepper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  titleTextStyle: {
    color: lightGrey,
    textAlign: "left",
    paddingLeft: "10px",
    fontWeight: "bold",
    marginBottom: "2px",
  },
  onOffButtonContainer: {
    display: "flex",
    padding: "0px 20px",
    justifyContent: "space-between",
  },
  onButton: {
    width: "50%",
    borderRadius: "20px 0px 0px 20px",
    height: "34px",
    backgroundColor: darkRed,
    color: white,
    fontWeight: "bold",
  },
  offButton: {
    width: "50%",
    borderRadius: "0px 20px 20px 0px",
    height: "34px",
    backgroundColor: lightGrey,
    color: white,
    fontWeight: "bold",
  },
  iconStyle: { height: "1.2em", width: "1.2em" },
  productTypeContainer: {
    display: "flex",
    // padding: "0px 20px",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  productListContainer: {
    width: "92%",
    height: "50vh",
    margin: "2%",
  },
  productListImageContainer: {
    height: "64%",
    background: lightGrey,
    position: "relative",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
  },
  productListPriceContainer: {
    position: "absolute",
    bottom: "0px",
    left: "23%",
    width: "56%",
    background: darkRed,
    height: "24%",
    borderRadius: "10px 10px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: white,
    fontWeight: "bold",
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  autoPlayContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryDropdown:{
    textTransform: "uppercase",
    width: "100%",
    height: "34px",
    fontSize: "smaller",
    backgroundColor: "inherit",
    color: white,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: 'none',
    outline: 'none',
    appearance: "none"
  }

};

class ProductsCarousel extends Component {
  constructor(props) {
    super(props);
    
    this.state = 
    {
      activeStep: 0,
      list: [],
      carousalProducts: [],
      start: 0,
      maxItemsToShow: 10,
      hasMore: true,
      categories: [
        {
          title: "All Products",
          slug: "",
        },
      ],
      scrollPosition:"",
      selectedCategorySlug: "All Products",
      selectedVendorId: "",
      anchorEl: null,
    };
    
}


  componentDidMount() {
    // window.document.querySelector('body').scrollTo(0,2000);
  //   window.onpopstate = e => {
  // alert("hellooooo");
  // };
    
    this.fetchInitData();
    // this.fetchCollections();
  }


   fetchInitData = async () => {

  
    let catResponse = this.getCategorySlug();
    let categorySlug = catResponse[0];
    let prevS = catResponse[1];


    this.setState((state) => ({
      ...state,
      selectedCategorySlug: categorySlug
    }));

    if (categorySlug !== '')
    {
    await getVendorDetails({name: categorySlug}).then((response) => {

      // console.log(response)

      this.setState(() => ({
        ...this.state,
        selectedVendorId : response.data? response?.data[0].id : '',
      }));
     });
    }

    // Need two parallel API calls here, one for sheroes-product-carousel, second for the selected category
    // Dont remove comments in this IF, Revert back the comments for parallel API calls
    
    if (!prevS)
    {
      Promise.all([
        getProducts({
          categorySlug: this.state.selectedVendorId,
          limit: this.state.maxItemsToShow,
        }),
        // getProducts({ categorySlug : this.state.selectedVendorId, limit: this.state.maxItemsToShow }),
      ])
        // .then(([carousalResponse, productsByCategoryResponse]) => {
        .then(([productsByCategoryResponse]) => {
          this.setState(() => ({
            ...this.state,
            // selectedCategorySlug: categorySlug,
            // carousalProducts: carousalResponse.data.products
            //   ? carousalResponse.data.products.slice(0, this.state.maxItemsToShow)
            //   : [],
            carousalProducts: productsByCategoryResponse.data.products || [],
            list: productsByCategoryResponse.data.products || [],
            hasMore:
              productsByCategoryResponse.data.count-10 <= this.state.start
              
                ? false
                : true,
          }));
        })
        .catch((err) => console.log("promise all error: ", err));

    }

  };

  fetchCollections = () => {
    getProductCollections("sheroes-products-collection").then((res) => {

      const categories = res.data[0]
        ? res.data[0].product_categories
        : this.state.categories;
      this.setState(() => ({
        ...this.state,
        categories: [...this.state.categories, ...categories],
      }));
    });
  };

  fetchProductsForCategory = () => {

    window.history.pushState("object or string", "Title", `?category=${this.state.selectedCategorySlug}`);

    getProducts({ categorySlug: this.state.selectedVendorId })
      .then((res) => {
        this.setState(() => ({
          // ...this.state,
          carousalProducts: res.data.products,
          list: res.data.products || [],
          hasMore: res.data.count-10 <= this.state.start ? false : true,
        }));
      })
      .catch((err) => console.log("getProductCategories error2: ", err));
  };

  getSelectedCategoryTitle = () => {
    // const category = this.state.categories.find(
    //   (item) => item.slug === this.state.selectedCategorySlug
    // );
    const category = this.state.selectedCategorySlug;

    return category ? category : "All Products";
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));

  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  handleCategoryButtonClick = (event) => {
    this.setState(() => ({
      ...this.state,
      anchorEl: event.currentTarget,
    }));
  };

  handleClose = async (category) => {

    if (category.title!==this.state.selectedCategorySlug)
    {
      
      await this.setState(() => ({
        ...this.state,
        anchorEl: null,
        selectedCategorySlug: category ? category.title : "",
        selectedVendorId: category ? category.slug : "",
        // activeStep: 0,
        list: [],
        // carousalProducts: [],
        start: 0,
        maxItemsToShow: 10,
        hasMore: true,
        // categories: [
        //   {
        //     title: "All Products",
        //     slug: "",
        //   },
        // ],
        scrollPosition:"",
        // selectedCategorySlug: "All Products",
        // selectedVendorId: "",
        // anchorEl: null,
      }));

      this.fetchProductsForCategory();
    }
    else
    {

    }

  };

  handleAddProductToMyShop = (product) => {
    addProductToMyShop(product)
      .then((res) => {
        toast.success("Item added to your shop");
        // console.log("product MyShop response: ", res.data);
      })
      .catch((err) => {
        toast.error("Something went wrong! Please try again.");
        console.log("add to shop error: ", err);
      });
  };

  getCategorySlug() {
    
    let categorySlug = new URLSearchParams(this.props.location.search).get(
      "category"
    );
    let prevS= false;
    categorySlug = categorySlug || "";
    // console.log(categorySlug)
    const data = JSON.parse(window.localStorage.getItem('data'));
    // console.log('data = ', data)
    // console.log('cat slug= ', categorySlug)
    // console.log('cat slug data= ', data?.selectedCategorySlug)
    
    if (categorySlug===data?.selectedCategorySlug && data?.list.length!==0)
    {
      // console.log('here')
      data.start= data.list.length-10 >0? data.list.length-10:0;
      // this.state=data;
      this.setState(data, ()=> {window.scrollTo(0,this.state.scrollPosition); 
        // console.log('inside func');
        // console.log('state here = ', this.state) 
      });
      prevS=true;
      // window.scrollTo(0,this.state.scrollPosition);
      
    }
    else
    {

    getVendorsList().then((res) =>{
      const newCategories = res.data;

    // newCategories.slice(0).reverse().map((item) => {
      for (let item of newCategories.slice(0).reverse())
      {
      

          let newCategory =           
            {
              title: item.name,
              slug: item.id
            }
          

          this.setState(() => ({
              ...this.state,
              categories: [...this.state.categories, newCategory]
            }));

          


          } //for bracket
  } //getVendorsList Brackets
  )  

  
  }
    // }
    
      
    return [categorySlug , prevS];
  }

  // Called by Infinite Scroll
  fetchMoreProducts = () => {
    let categorySlug = this.state.selectedCategorySlug;
    // console.log('selcted categor', this.state.selectedCategorySlug);
    // console.log('selcted venidcat', this.state.selectedVendorId);
    // if (!categorySlug) {
    //   categorySlug = this.getCategorySlug();
    // }
    // console.log(this.state.start+10)
    // console.log(this.state.selectedVendorId)
    
    // console.log(categorySlug)
    // console.log(this.state.selectedVendorId, this.state.selectedCategorySlug)
    if(this.state.selectedVendorId!=="")
    {
    // console.log('if')
    this.setState((state) => ({
      ...state,
      selectedCategorySlug: categorySlug,
      start: state.start + state.maxItemsToShow,
    }));
    getProducts({
      categorySlug: this.state.selectedVendorId,
      start: this.state.start,
      limit: this.state.maxItemsToShow,
    })
      .then((res) => {
        
        
        if (res.data.count-10 <= this.state.start) {
          this.setState({
            ...this.state,
            hasMore: false,
            list: [...this.state.list, ...res.data.products],
          });
        } else {

          this.setState(() => ({
            ...this.state,
            list: [...this.state.list, ...res.data.products],
          }));
        }
      })
      .catch((err) => {console.log("getProductCategories error: ", err); 
     });

    }
    else if (categorySlug==='' || categorySlug==='All Products')
    {
      // console.log('else if')
      this.setState((state) => ({
        ...state,
        start: state.start + state.maxItemsToShow,
      }));
      getProducts({
        start: this.state.start,
        limit: this.state.maxItemsToShow,
      })
        .then((res) => {
          
          
          if (res.data.count-10 <= this.state.start) {
            this.setState({
              ...this.state,
              hasMore: false,
              list: [...this.state.list, ...res.data.products],
            });
          } else {
  
            this.setState(() => ({
              ...this.state,
              list: [...this.state.list, ...res.data.products],
            }));
          }
        })
        .catch((err) => console.log("getProductCategories error: ", err)); 
    }
    else{
      // console.log('else')
    }




  };

  navigateToProductPage = (product) => {
    this.props.history.push(`/products/${product.id}`);
  };


  saveScroll = () => {
    // window.scrollTo(0,20000);
      // console.log('scrolling ',window.pageYOffset)
      window.localStorage.setItem('data', JSON.stringify(this.state))
      this.setState({
        ...this.state,
        scrollPosition: window.pageYOffset
    });
    window.localStorage.setItem('data', JSON.stringify(this.state))
    // console.log('scrolling state ',this.state.scrollPosition)
    // const data = JSON.parse(window.localStorage.getItem('data'));
    // console.log('data = ', data)
    // console.log('state = ', this.state)
  }

  render() {
    const { activeStep } = this.state;
    const { classes } = this.props;
    const maxSteps =
      this.state.carousalProducts.length < this.state.maxItemsToShow
        ? this.state.carousalProducts.length
        : this.state.maxItemsToShow;

    return (
      <div>
        <TopNavbar />

        <div className={classes.productHomeContainer}>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={this.handleStepChange}
            enableMouseEvents
          >
            {this.state.carousalProducts &&
            this.state.carousalProducts.length &&
            this.state.carousalProducts ? (
              this.state.carousalProducts
                .slice(0, this.state.maxItemsToShow)
                .map((step, index) => (
                  <div
                    className={classes.autoPlayContainer}
                    key={index}
                    onClick={() => this.navigateToProductPage(step)}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img
                        className={classes.imgStyle}
                        src={step.primaryImageSrc.imageSrc}
                        alt={step.title}
                      />
                    ) : null}
                  </div>
                ))
            ) : (
              <div></div>
            )}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
          />
          <div className={classes.productTypeContainer}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >


      <FormControl className={classes.formControl }      >
        <Fab
                className={classes.productDropDown}
                variant="extended"
                style={{
                  width: "100%",
                  height: "34px",
                  backgroundColor: "#ff4343",
                  color: white,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "smaller",
                }}
                onClick={this.handleCategoryButtonClick}
                
              >

        <select

        className={classes.categoryDropdown}
          value={JSON.stringify({'title': this.state.selectedCategorySlug, 'slug': this.state.selectedVendorId})}
          onChange={(e) => (this.handleClose(JSON.parse(e.target.value)))}
        >
                {this.state.categories.map((item) => (
                  <option
                  style={{
                    textTransform: "capitalize",
                    color: "black",
                  }}
                    key={item.slug}
                    value = {JSON.stringify(item)}

                  >
                    {item.title}
                  </option>))}
        </select>
        <ArrowDropDown className={classes.iconStyle} />
        </Fab>
        {/* </Select> */}
      </FormControl>


            </div>
            {/* <div
              style={{
                width: "48%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Fab
                variant="extended"
                style={{
                  width: "100%",
                  height: "34px",
                  backgroundColor: "#ff4343",
                  color: white,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "smaller",
                }}
              >
                <span style={{ fontSize: "smaller" }}> Highly Rated</span>
                <ArrowDropDown className={classes.iconStyle} />
              </Fab>
            </div> */}
          </div>



          <div
            style={{
              display: "flex",
              //  padding: "0px 20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              flexWrap: "wrap",
            }}
          >
            <InfiniteScroll
              
              dataLength={this.state.list.length}
              next={this.fetchMoreProducts}
              hasMore={this.state.hasMore}
              loader={
                <div style={{ paddingTop: "15vh" }}>
                  <Loader />
                </div>
              }
              onScroll={this.saveScroll}
              initialScrollY={0}
              endMessage={
                this.state.list.length > 0 ? (
                  <span></span>
                ) : (
                  <p
                    style={{
                      color: "#BFBFBF",
                      marginTop: "150px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{
                        height: "75px",
                        width: "75px",
                        filter: "invert(0.25)",
                        marginBottom: "15px",
                      }}
                      src={require("../../assets/images/Search.svg")}
                      alt="magnifier-img"
                    />
                    <p>
                      <b>No products found</b>
                    </p>
                  </p>
                )
              }
            >
              {/* {JSON.stringify(this.state.list.length)} */}
              {this.state.list?.map((item, index) => (

                <ProductCard
                  
                  product={item}
                  key={index}
                  addProductToMyShop={this.handleAddProductToMyShop}
                />
                
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ProductsCarousel);
