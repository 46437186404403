import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, InputAdornment } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const MobileForm = ({ mobileNo, handleOnChange }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="subtitle2">Enter your mobile number</Typography>
      <Typography variant="caption">
        Login in to your SHECO profile with your personal mobile number.
      </Typography>

      <TextField
        className={classes.textField}
        id="input-with-icon-textfield"
        value={mobileNo}
        onChange={(event) => handleOnChange("mobile", event)}
        onKeyPress={(event) => handleOnChange("mobile", event)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography className={classes.alignText}>
                +91 <ExpandMoreIcon />
              </Typography>
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: 10,
          pattern: "[0-9]*",
        }}
      />
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    margin: theme.spacing(1, 0, 2, 0),
  },
  alignText: {
    display: "flex",
    alignItems: "center",
  },
}));
export default MobileForm;
