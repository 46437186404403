import React from 'react'
import "./styles.scss";

class ImageLoader extends React.Component {
    constructor() {
        super();
        this.state = { loaded: false };
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loaded ? null :
                    <div className="loading-background" />
                }
                <div className="product-logo" >
                    <img
                        style={this.state.loaded ? { overflow: "hidden" } : { display: 'none' }}
                        {...this.props}
                        onLoad={() => this.setState({ loaded: true })}
                        alt=""
                    /></div>
            </React.Fragment >
        );
    }
}

export default ImageLoader;