import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/styles/App.scss";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home/Home";
import { Provider } from "react-redux";
import { store } from "./store";
import Header from "./components/header/Header";
import Communities from "./pages/community/Communities";
import ProductsHome from "./pages/products/ProductsHome";
import Cart from "./pages/cart/Cart";
import SingleProduct from "./pages/products/SingleProduct";
import MyShop from "./pages/my-shop/MyShop";
import SearchResult from "./pages/search/SearchResult";
import Splash from "./pages/splash";
import { ToastContainer } from "react-toastify";
import ArticleDetail from "./pages/articles/articleDetail";
import Login from "./pages/login/Login";
import MobileEmail from "./pages/login/MobileEmail";
import VerifyOtp from "./pages/login/verifyOtp";
import MetaData from "./components/common/metaData";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        closeOnClick={true}
        closeButton={false}
        limit={3}
      />
      <MetaData />
      <Router>
        <div className="App">
          <div>
            <Header />
          </div>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/mobile-email-form" exact component={MobileEmail} />
            <Route path="/verify-otp" exact component={VerifyOtp} />

            <Route path="/" exact component={Splash} />
            <Route path="/home" component={Home} />
            <Route
              path="/products"
              render={({ match: { url } }) => (
                <>
                  <Route path={`${url}/`} component={ProductsHome} exact />
                  <Route path={`${url}/:id`} component={SingleProduct} exact />
                </>
              )}
            />
            <Route
              path="/articles"
              render={({ match: { url } }) => (
                <>
                  <Route
                    path={`${url}/:articleTitleSlug/:id`}
                    component={ArticleDetail}
                    exact
                  />
                </>
              )}
            />
            <Route path="/communities" component={Communities} />
            <Route path="/cart" component={Cart} />
            <Route path="/my-shop" component={MyShop} />
            <Route path="/search" component={SearchResult} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
