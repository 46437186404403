export const communityData = [
  {
    id: 1,
    title: "She Starts Up",
    subTitle: "Careers and Skill Building",
    numMembers: "231.8k",
    introText: `Lorem Ipsum Dorem Miso Lorem Ipsum Dorem Miso Lorem 
    Ipsum Dorem Miso Lorem Ipsum Dorem Miso 
    Lorem Ipsum Dorem Miso style statement`,
  },
  {
    id: 2,
    title: "Fashion and Lifestyle",
    subTitle: "Careers and Skill Building",
    numMembers: "231.8k",
    introText: `Lorem Ipsum Dorem Miso Lorem Ipsum Dorem Miso Lorem 
    Ipsum Dorem Miso Lorem Ipsum Dorem Miso 
    Lorem Ipsum Dorem Miso style statement`,
  },
];
