import React from "react";
import "./Footer.scss";
import { useSelector } from "react-redux";
//useDispatch, import { OPEN_LOGIN_MODAL } from "../../actions/actionTypes";
import { NavLink } from "react-router-dom";

export default () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  //const dispatch = useDispatch();

  return (
    <div>
      {isLoggedIn ? (
        ""
      ) : (
        <div className="footer-bottom-card-section footer-top-curved pt-20 ">
          <div className="uppercase footer-bottom-card-info">
            <p className="font-18 white">
              <span>Be a Sheco Partner on Sheroes!</span>
            </p>
            <div className="row cta-row mb-4">
              {/* <div className="footer-cta font-16 uppercase bg-white p5 mb-3">
                <a
                  className="mb-0"
                  //onClick={() => dispatch({ type: OPEN_LOGIN_MODAL })}
                  href="https://wa.me/+919354285436"
                >
                  Register with us!
                </a>
              </div> */}
              <br />
              <div className="footer-cta font-16 uppercase bg-white p5">
                <NavLink
                  to="/mobile-email-form"
                  activeClassName="active-link"
                  exact
                >
                  Login
                </NavLink>
              </div>
              <div className="footerLink">
                <a href="https://sheroes.com/Home/tnc">Terms & Conditions</a> |{" "}
                <a href="https://sheroes.com/Home/policy">Policy </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
