import React, { Component } from "react";
import FileCopySharpIcon from "@material-ui/icons/FileCopySharp";
import { withStyles } from "@material-ui/core/styles";
const styles = {
  clickToCopy: {
    float: "right",
    background: "#94C93D",
    color: "#fff",
  },
  successCopy: {
    color: "green",
    position: "absolute",
    right: "0",
    bottom: "-75px",
    fontSize: "13px",
    fontWeight: "900",
  },
};

class ClickToCopy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textToCopy: props.text,
      copySuccess: false,
    };
  }
  handleClick = (text) => {
    if (!navigator.clipboard) {
      this.copyClipBoard(text);
      this.setState({ copySuccess: true });
      setTimeout(() => {
        this.setState({ copySuccess: false });
      }, 2000);
      return;
    }
    navigator.clipboard.writeText(text);
    this.setState({ copySuccess: true });
    setTimeout(() => {
      this.setState({ copySuccess: false });
    }, 2000);
  };
   copyClipBoard=(text)=> {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          title="Click to Copy Description"
          className={`${classes.clickToCopy} btn btn-secondary`}
          onClick={() => {
            this.handleClick(this.state.textToCopy);
          }}
        >
          <FileCopySharpIcon alt="Click to Copy" />
        </button>
        {this.state.copySuccess ? (
          <span className={classes.successCopy}>Copied!</span>
        ) : null}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ClickToCopy);
