import { SHEROES_API_URL, GET_OTP, VALIDATE_OTP } from "../constants/api";
import HttpClient from "../services/http-client";
import { getOtpParams } from "../utils/userUtil";

export async function getOtp(type, request) {
  const OtpUrl = getOtpParams(type, request);
  const url = SHEROES_API_URL + GET_OTP + OtpUrl;
  return await HttpClient.get(url).then((res) => res);
}

export async function validateOtp(request) {
  const { data } = await HttpClient.post(
    `${SHEROES_API_URL + VALIDATE_OTP}`,
    null,
    request,
    {}
  ).then((res) => res);
  // if (data.status === "FAILED") {
  //   return data.error_message;
  // }
  return data;
  //return processLoginResponse(data, "otp");
}
