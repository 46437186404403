const data = [{"type":"collection","stories":[
{"authors":[],
"_id":"601beb263d28a64f4cee920b","published_at":"2021-02-04T12:40:08.256Z","slug":"faq-2","subtitle":"On SHECO you get access to exclusive  branded products where you can earn high commissions. You get to decide your own margins; get constant support from mentors and get access to learning material; videos, webinars and trainings","title":"Why should I sell on SHECO?","data":[],"createdAt":"2021-02-04T12:40:06.337Z","updatedAt":"2021-02-09T07:11:39.732Z","__v":0,"id":"601beb263d28a64f4cee920b"},
{"authors":[],
"_id":"601beb493d28a64f4cee920c","published_at":"2021-02-04T12:40:43.781Z","slug":"faq-3","subtitle":"A SHECO Partner is a woman entrepreneur who sells SHECO Products in her network and earns a commission on each sale along with cash incentives.\n\nAs a Verified SHECO Partner, you will get: \n1. SHECO Partner badge on your SHEROES Profile \n2. Exclusive shop section on your SHEROES profile \n3. Chat feature through which you can DM anybody on SHEROES  \n 4. Access to product training, learning material and  webinars \nTo become a verified SHECO Partner, register via this link: http://go.sheroes.com/public/go/sheco/verifiedseller","title":"Who is SHECO Partner and why should I become one?","data":[],"createdAt":"2021-02-04T12:40:41.731Z","updatedAt":"2021-02-09T07:13:49.878Z","__v":0,"id":"601beb493d28a64f4cee920c"},
{"authors":[],
"_id":"602236f83d28a64f4cee926a","published_at":"2021-02-09T07:17:24.805Z","subtitle":"Becoming a SHECO Partner is a 3 step process.\n \n 1. Click on the register now button on the homepage. \n 2. Submit your KYC document for verification on app or at shecommerce@sheroes.in\n 3. Get access to SHECO Products and start selling\n \nOnce the documents are submitted, SHECO team will verify your profile in 24 hours ","title":"How can I become a SHECO Partner?","data":[],"createdAt":"2021-02-09T07:17:12.403Z","updatedAt":"2021-02-09T07:27:56.997Z","__v":0,"id":"602236f83d28a64f4cee926a"},
{"authors":[],
"_id":"602237883d28a64f4cee926c","published_at":"2021-02-09T07:19:38.412Z","subtitle":"The SHECO support team is reachable at shecommerce@sheroes.in and Whatsapp - 93542 85436 (Monday to Friday - 9am to 6 pm)","title":"Where can I contact SHECO support team?","data":[],"createdAt":"2021-02-09T07:19:36.694Z","updatedAt":"2021-02-09T07:42:29.363Z","__v":0,"id":"602237883d28a64f4cee926c"},
{"authors":[],
"_id":"6022375f3d28a64f4cee926b","published_at":"2021-02-09T07:18:56.737Z","subtitle":"Doing your KYC/summiting documents will help us authenticate your identity and also transfer your cash incentives (as and when you earn) to your bank account","title":"Why do I need to to do my KYC/Submit my documents?","data":[],"createdAt":"2021-02-09T07:18:55.148Z","updatedAt":"2021-02-09T07:42:37.956Z","__v":0,"id":"6022375f3d28a64f4cee926b"},
{"authors":[],
"_id":"601beb033d28a64f4cee920a","published_at":"2021-02-04T12:39:34.306Z","slug":"faq-1","subtitle":"SHECO is India's first women only social selling platform where you can\n\n1. Work from home with zero investment \n2. Sell  branded products in your network\n3. Become financially independent \n4. Access training and learning tools\n5. Get constant support to help you learn and grow. \n\nSHECO is a platform where you create your own identity, give wings to your dreams and fly high by becoming successful entrepreneur. Watch this video to know more\\nhttps://youtu.be/JNZBcsifhSk","title":"What is SHECO?","data":[],"createdAt":"2021-02-04T12:39:31.980Z","updatedAt":"2021-02-19T16:29:01.266Z","__v":0,"id":"601beb033d28a64f4cee920a"}],"_id":"601beb893d28a64f4cee920d","name":"Sheroes FAQs Collection","slug":"sheroes-faqs-collection","published_at":"2021-02-04T12:41:47.397Z","createdAt":"2021-02-04T12:41:45.303Z","updatedAt":"2021-02-19T16:29:01.265Z","__v":0,"id":"601beb893d28a64f4cee920d"}
]
export default data;