import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField } from "@material-ui/core";

const EmailForm = ({ emailId, handleOnChange }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="subtitle2">Enter your email ID</Typography>
      <Typography variant="caption">
        Login in to your SHECO profile with your personal email ID.
      </Typography>
      <TextField
        className={classes.textField}
        id="input-with-icon-textField"
        value={emailId}
        onChange={(event) => handleOnChange("email", event)}
        onKeyPress={(event) => handleOnChange("email", event)}
      />
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    margin: theme.spacing(1, 0, 2, 0),
  },
}));
export default EmailForm;
