import React from "react";
import { Box } from "@material-ui/core/";

const RenderIframe = ({ url }) => {
  function renderIframe(url) {
    return (
      '<iframe frameborder=0 src="' +
      url +
      '" width="100%" height="800"></iframe>'
    );
  }
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: renderIframe(url),
      }}
    ></Box>
  );
};

export default RenderIframe;
