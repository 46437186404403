export const BASE_API_URL = "https://staging.shecobysheroes.com:1338";
export const NEW_BASE_API_URL =
  "https://backend.shoptype.com/platforms/1e4cfbf9-bb51-1178-b34a-66370d426a43";
export const SHEROES_MIDDLEWARE_BASE_PATH =
  "https://staging.shecobysheroes.com:8081";
//export const SHEROES_MIDDLEWARE_BASE_PATH = "https://backend.shoptype.com"; // "https://staging.shecobysheroes.com:8081";
// export const SHEROES_MIDDLEWARE_BASE_PATH = 'http://localhost:8080'
export const USER_EVENT_API = "https://backend.shoptype.com/user-event";

export const SECTIONS_API = "sections/";
export const STORIES_API = "stories/";
export const PARTNER_AUTH_API = "user/partner-auth/";
export const PRODUCTS_API = "products/";
export const GET_PRODUCTS_API = "products";
export const PRODUCT_CATEGORIES_API = "product-categories/";
export const PRODUCT_COLLECTIONS_API = "product-collections/";
export const PRODUCTS_CART_API = `${PRODUCTS_API}cart/`;
export const STORY_COLLECTIONS_API = `collections/`;
export const PRODUCT_WISHLIST_API = `${PRODUCTS_API}wishlist/`;
export const PRODUCT_CHECKOUT_API = `${PRODUCTS_API}checkout/`;
export const LOGIN_API = `user/login/`;
//export const LOGIN_API = `authenticate`;
export const SHEROES_API_URL = "https://services.sheroes.in";
export const GET_OTP = "/participant/user/verification/request";
export const VALIDATE_OTP = "/participant/user/verification/validate";
