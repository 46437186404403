import React from 'react'
import "./styles.scss"

export default ({ size = "120px" }) => (
  <div
    style={{
      height: size,
      width: size,
      overflow: "hidden",
      margin: "auto"
    }}
  >
    <div
      className="loader"
      style={{
        height: size,
        width: size,
      }}
    ></div>
  </div>
)