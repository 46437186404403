import React, { Component } from "react";
class Prices extends Component{
render()
{   
    const {
        productDetail,
        selectedVariant,
      } = this.props;
    return(
        <div>
        {/* Strike through price and show a discounted price */}
        {productDetail.product &&
        selectedVariant &&
        selectedVariant.discountedPrice ? (
          <div style={{ float: "left" }}>
            <span style={{ fontSize: "2vh" }}>
              <span style={{ color: "#FF4343" }}>MRP: </span>{" "}
              {productDetail.product.currency}{" "}
              <span>{selectedVariant.price}</span>{" "}
            </span>
            <div style={{ fontSize: "2vh" }}>
              <span style={{ color: "#FF4343" }}>
                SHECO Partner Price:{" "}
              </span>
              {productDetail.product.currency} {""}
              {selectedVariant.discountedPrice}
            </div>
            <div style={{ fontSize: "2vh" }}>
              <span style={{ color: "#FF4343" }}>Commission: </span>
              {productDetail.product.currency}{" "}
              {selectedVariant.price -
                selectedVariant.discountedPrice}
            </div>
          </div>
          ) : null}  
        {/* whatsapp logo right aligned */}
      </div>
    )
}
}export default Prices;