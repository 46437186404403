import React, { useEffect, useState } from "react";
import "./FAQ.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
//import { getStoryCollections } from "../../lib/api";
import { useSelector } from "react-redux";
import Loader from "../loader";
import dataResponse from "./FAQdata";

export default () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [faq, setfaq] = useState([]);
  const [faqLoading, setFaqLoading] = useState(true);

  useEffect(() => {
 //   getStoryCollections("sheroes-faqs-collection").then((res) => {
      setFaqLoading(false);
      dataResponse && setfaq(dataResponse[0].stories);
   // });
  }, []);

  return (
    <div>
      <div
        className={`container-fluid ${isLoggedIn ? "" : "mb-10em"}`}
        style={{
          backgroundColor: "#F7F7F7",
          paddingTop: "25px",
        }}
      >
        <div className="blog-title-container mb25 ml0 mr0">
          <div className="section-title gray29">FAQ</div>
          {/* <div className="">
            <p className="footer-link coral-red mb0">View all</p>
          </div> */}
        </div>
        {faqLoading ? (
          <Loader size={"60px"} />
        ) : (
          <div className="row">
            <div className="col">
              <Carousel>
                {faq.length > 0 &&
                  faq.map((item, index) => (
                    <div className="card card-body" key={index}>
                      <p className="card-text"></p>

                      <div className="card-text">
                        <p>{item.title}</p>
                        <p>{item.subtitle}</p>
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
