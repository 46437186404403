import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FETCHED_PRODUCT_CATEGORIES,
  FETCHING_PRODUCT_CATEGORIES,
} from "../../actions/actionTypes";
import { getVendorsList } from "../../lib/api";
import "./ProductCategoriesSection.scss";
import ImageLoader from "../../components/image-loader";
import Loader from "../loader";




export default ({ isLoggedIn }) => {



  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector((state) => state.products.productCategories);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: FETCHING_PRODUCT_CATEGORIES });


    getVendorsList().then((res) => {
      const categories = res.data;

      setCategoriesLoading(false);
      dispatch({
        type: FETCHED_PRODUCT_CATEGORIES,
        payload: categories,
      });
    });



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCategoryClick = (e, item) => {
    e.preventDefault();
    if (isLoggedIn) {
      history.push(`/products/?category=${item.name}`, item.id);
    }
  };


  return (

    <div>

      <div className="pt25 pb25">
        <div className="mb25 section-title-container">
          <div className="section-title gray29">SHECO Catalog for you </div>
        </div>


        {categoriesLoading ? (
          <Loader size={"60px"} />
        ) : (
          <div className="categories-wrapper">
            {categories.slice(0).reverse().map((item, index) => (
              <div
                className={
                  index === 0
                    ? "category-box col-11 m10"
                    : "category-box col-5 m10"
                }
                key={index}
                onClick={(e) => handleCategoryClick(e, item)}
              >
                {item.logo && (
                  <ImageLoader
                    src={item.logo}
                    alt={item.logo}
                  />
                )
                }
                <p className="text-center category-text">{item.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};




