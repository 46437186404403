import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import {getVendorDetails} from "../../lib/api";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export default function ProductVendorBanner(props) {
  const classes = useStyles();
  const [details, setDetails] = useState(props.details);
  // const [productName, setProductName] = useState('');

  const handleExpandClick = () => {
    window.location.replace(`products/?category=${details.slug}`);
  };
  useEffect(() => {

    fetchData();
    // eslint-disable-next-line
  },[])



  const fetchData = () => {

    if (details.imageLink==="")
    {
    getVendorDetails({vendorId: details.slug}).then((response) => {
        setDetails({
            ...details,
            imageLink: response.data[0].logo,
            slug: response.data[0].name,
        });
      });
    }
  
  };

  return (
      
    <div className="pt25 pb25" align="center">
    <div className="mb25 section-title-container">
      <div className="section-title gray29">{details.title}</div>
    </div>

    <Card className={classes.root}>
      <CardMedia
        onClick={handleExpandClick}
        className={classes.media}
        image= {details.imageLink}
        title= {details.altText}
      />
    </Card>
    </div>
    // </div>
  );
}
