import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { searchModalClose } from "../../actions/actions";
import qs from "qs";
import CloseIcon from "@material-ui/icons/Close";
import "./search.scss";
import { getProductsSearch } from "../../lib/api";
import {
  FETCHED_PRODUCTS_SEARCH_RESULTS,
  SET_SEARCH_QUERY,
} from "../../actions/actionTypes";
import { useHistory } from "react-router-dom";

const SearchCustomModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const keyPressed = (e) => {
    if (e.keyCode === 13) {
      const query = qs.stringify({

            text : searchQuery
      //   _where: {
      //     _or: [
      //       { title_contains: searchQuery },
      //       { description_contains: searchQuery },
      //     ],
      //   },
      });
      // console.log(query);
      dispatch({ type: SET_SEARCH_QUERY, payload: searchQuery });
      getProductsSearch({ query })
        .then((res) => {
          // console.log(res)
          dispatch({
            type: FETCHED_PRODUCTS_SEARCH_RESULTS,
            payload: res.data.products,
          });
          dispatch(searchModalClose());
          history.push("/search");
        })
        .catch((err) => console.log("search error: ", err));

      // dispatch({
      //   type: FETCHED_PRODUCTS_SEARCH_RESULTS,
      //   payload: ProductData,
      // });
      // dispatch(searchModalClose());
      // history.push("/search");
    }
  };

  return (
    <div className="custom-modal-container">
      <div
        className="overlay"
        onClick={() => dispatch(searchModalClose())}
      ></div>
      <div
        className="custom-modal-dialog"
        style={{
          position: "absolute",
          top: "0",
          borderRadius: "0",
        }}
      >
        <div className="row mt15 mb15">
          <div className="col-10">
            <input
              type="text"
              autoFocus
              className="search-input"
              placeholder="Eg. Health"
              value={searchQuery}
              onChange={(e) => handleChange(e)}
              onKeyDown={keyPressed}
            />
          </div>
          <div className="col-2">
            <CloseIcon
              className="close-icon"
              onClick={() => setSearchQuery("")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCustomModal;
