import { ISD_CODE } from "../constants/global";
//import { GET_OTP } from "../constants/api";

function getOtpParams(type, request) {
  const isdCode = encodeURIComponent(ISD_CODE);
  const params =
    type === "mobile"
      ? "?isd=" + isdCode + "&mobile=" + request
      : "?email=" + request;
  return params;
}
function getOtpRequest(activeForm, mobileNo, isdCode, otp, emailId) {
  const request = {};
  if (activeForm === "mobile") {
    request.mobile = mobileNo;
    request.isd = isdCode;
    request.otp = otp;
  } else {
    request.email = emailId;
    request.otp = otp;
  }
  return request;
}

export { getOtpParams, getOtpRequest };
