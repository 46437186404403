import React, { Component } from "react";
import { toast } from "react-toastify";
import { addProductToCart } from "../../lib/api";
import { updateProductQuantityInCart } from "../../lib/api";
import Button from "@material-ui/core/Button";
import { white} from "../../colors/color";
class AddToCart extends Component {
    constructor(props){
        super(props) 
        this.state = {
            productDetail: props.shoptypeData,
            selectedVariant: {},
            productQuantity: 1,
            productExistingInCart: false,
        }
      };
        addToCart() {
          const id = this.state.productDetail.id;          
          const shoptypeData = this.props.productDetail.product;
          const {  productQuantity, productExistingInCart, selectedVariant } = this.props;
          const variantId = this.props.selectedVariant.id; 
          // const productId = this.props.productDetail.product.id;
          //   if (variantId === null || variantId === " "){
          //     alert("Something Went Wrong!");
          //     return false;
          //   }
          if (productExistingInCart) {
            // Call the patch API
           
            updateProductQuantityInCart({
              variantId,
              productId: id,
              orderQuantity: productQuantity,
            })
              .then((res) => {
                this.props.history.push("/cart");
              })
              .catch((err) => {
                console.log("add to cart error: ", err);
              });
          } else {
            const payload = {
              product: shoptypeData,
              productId: id,
              variantId: selectedVariant.id,
              orderQuantity: productQuantity,
            };
            addProductToCart(payload)
              .then((res) => {
                toast.success("Item added to your cart");
                this.props.history.push("/cart"); 
              })
              .catch((err) => {
                toast.error("Something went wrong! Please try again.");
                console.log("add to cart error: ", err);
              });
          }  
        }    
render(){
           //console.log(this.props);
          return(
          <Button
            variant="contained"
            style={{
              padding: "12px 12px",
              color: "#4A4A4A",
              backgroundColor: white,
              fontWeight: "700",
              fontSize: "18px",
            }}
            onClick={() => this.addToCart()}
            disabled={!this.state.productQuantity}
          >
            ADD TO CART
          </Button>
          )
        }
};  
export default AddToCart;