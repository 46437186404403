import React, { Component } from "react";
import Filter from "../../components/filter/Filter";
import { lightGrey } from "../../colors/color";
class UpdateQuantityAndDescription extends Component{
    render(){
        const {
          productDetail,
          selectedOptions,
          selectedVariant,
          handleQuantityChange,
          filterSelected,
        } = this.props;
        return(
            <div> 
            {productDetail && productDetail.product ? (
                <Filter
                  options={productDetail.product.options}
                  selectedFilter={selectedOptions}
                  handleFilterSelect={filterSelected}
                  currentVariantAvailableQty={
                    selectedVariant.quantity
                  }
                  handleQuantityChange={handleQuantityChange}
                  initialQuantity={this.props.productQuantity}
                />
              ) : (
                ""
              )}

              <div
               style={{
                margin: "14px 22px",
                display: "flex",
                flexDirection: "column",
               }}
              >
                {productDetail &&
                  productDetail.product &&
                  productDetail.product.variants &&
                  productDetail.product.variants[0].quantity < 5 ? (
                    productDetail.product.variants[0].quantity === 0 ? (
                      <div style={{ color: "red" }}>Out of stock</div>
                    ) : (
                      <span style={{ color: "green" }}>
                        Less than 5 pieces left
                      </span>
                    )
                  ) : (
                    <div style={{ color: "green" }}>In Stock</div>
                  )}
                
          <span
            style={{
              fontSize: "2.2vh",
              fontWeight: "bold",
              color: "#4A4A4A",
            }}
          >
            Product Description

          </span>
          <div
            style={{
              color: lightGrey,
              whiteSpace: "pre-wrap",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {productDetail.product.description}
            {/* {JSON.stringify(this.state.similarProductsList)} */}
             <br/>
          </div> 
                </div>
            </div>
        )
    }
}; export default UpdateQuantityAndDescription;