import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
//import { MEDIA_PROXY_URL } from "../../constants/global";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// import React, { useState } from "react";
const styles = {
  downloadButton: {
    float: "right",
    background: "#94C93D",
    color: "#fff",
    marginRight: "10px",
    downloading: false,
  },

  successDownload: {
    color: "green",
    position: "absolute",
    right: "60px",
    // left: "0",
    bottom: "-75px",
    fontSize: "13px",
    fontWeight: "900",
  },
};

class DownloadImages extends Component {
  constructor(props) {
    super(props);
    let imageToShare = props.images.map((image) => image.imageSrc);
    this.state = {
      imageUrl: "",
      imageArray: [...imageToShare],
      progress: 0,
      downloadSuccess: false,
      downloading: false,
      icon: 0,
    };
  }

  fileDownloadHandler = async (pictures) => {
    var d = new Date();
    var n = d.getTime();
    for (var i = 0; i < pictures.length; i++) {
      let percentage = ((i + 1) / pictures.length) * 100;
      this.setState((i) => ({
        ...this.state,
        progress: percentage,
        downloading: true,
      }));
      // percentage === 100 is used for products with single image
      if (this.state.progress === 100 || percentage === 100) {
        this.setState(() => ({
          ...this.state,
          progress: 0,
          downloading: false,
          icon: 1,
          downloadSuccess: true,
        }));
        setTimeout(() => {
          this.setState({ ...this.state, icon: 0, downloadSuccess: false });
        }, 2000);
      }
      // this.state.progress = (i+1/ pictures.length)*100
      //const response = await fetch(pictures[i]);
      //const response = await fetch(MEDIA_PROXY_URL + pictures[i]);
      const response = await fetch(pictures[i], {
        method: "GET",
        headers: { "Cache-Control": "no-cache" },
      });
      const counter = n + i;
      const pictureName = "picture_" + counter + ".jpeg";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = pictureName;
        a.click();
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {!this.state.downloading ? (
          <div>
            <button
              type="button"
              title="Download Images"
              className={`${classes.downloadButton} btn btn-secondary`}
              onClick={() => this.fileDownloadHandler(this.state.imageArray)}
            >
              {this.state.icon ? (
                <CheckCircleIcon />
              ) : (
                <GetAppSharpIcon alt="Download Images" />
              )}
            </button>

            {this.state.downloadSuccess ? (
              <span className={classes.successDownload}>Downloaded!</span>
            ) : null}
          </div>
        ) : (
          <div className={`${classes.downloadButton} btn btn-secondary`}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                color={"inherit"}
                size="1.45rem"
                thickness={10}
                value={this.state.progress}
              />
              {/* <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" size='1rem' color="textSecondary">{`${Math.round(
         this.state.progress
        )}%`}</Typography>
      </Box> */}
            </Box>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(DownloadImages);
