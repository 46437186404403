import React, { Component } from "react"
import DownloadImages from "../../pages/products/DownloadImages";
import ClickToCopy from "../../pages/products/ClickToCopy";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { withStyles } from "@material-ui/core/styles";
import MetaData from "../../components/common/metaData";
import { ellipSize } from "../../utils/textUtil";
// import { allSettled } from "../../helpers/utils";
// import { MEDIA_PROXY_URL } from "../../constants/global";
import "./singleProductCopy.css";
const styles = {
    actionContainer: {
        display: "block",
        margin: "5px 0px",
        position: "relative",
      },
      // whatsApp: {
      //   float: "right",
      //   marginRight: "10px",
      //   background: "#94C93D",
      //   color: "#fff",
      // },
};
class ShareCopyDownload extends Component{
    constructor(props) {
        super(props);
        this.state = {
          productDetail: {},
        };
      }
      // shareSubmit = async (e) => {
      //   const  shoptypeData  = this.props.productDetail.product;
      //   const imageTitle = `*${shoptypeData.title}* - ${shoptypeData.description}`;
      //   const imageSrc = shoptypeData.primaryImageSrc.imageSrc;
      //   let imageURL =
      //     "whatsapp://send?text=" + imageTitle + " " + encodeURIComponent(imageSrc);
    
      //   const images =
      //     shoptypeData.secondaryImageSrc && shoptypeData.secondaryImageSrc.length
      //       ? [shoptypeData.primaryImageSrc, ...shoptypeData.secondaryImageSrc]
      //       : [shoptypeData.primaryImageSrc];
    
      //   // Image List send
      //   let imageBlobs = await allSettled(
      //     images.map(async (item) => {
      //       let response = await fetch(MEDIA_PROXY_URL + item.imageSrc);
      //       console.log(response);
      //       // let response = item.imageSrc;
      //       return response.blob();
      //     })
      //   );
    
      //   let files = [];
      //   imageBlobs.forEach((response, index) => {
      //     if (response.status === "fulfilled") {
      //       files.push(
      //         new File([response.value], `share.jpg`, {
      //           type: response.value.type,
      //         })
      //       );
      //     }
      //   });
    
      //   console.log(files);
    
      //   if (navigator.canShare && navigator.canShare({ files: files })) {
      //     navigator
      //       .share({
      //         text: imageTitle,
      //         title: "Check this out!",
      //         files: files,
      //       })
      //       .then(() => console.log("Successful share"))
      //       .catch((error) => console.log("Error in sharing", error));
      //   } else {
      //     console.log(`system does not support sharing files.`);
      //     window.location.href = imageURL;
      //   }
      // };
render(){
    const { classes } = this.props;
    const {
        productDetail,
      } = this.props;

    const ImageTitle =
        productDetail && productDetail.product
        ? productDetail.product.title
        : "";

    const deepLinkUrl =
        productDetail && productDetail.product ? productDetail.product._id : "";

    const fullOgUrl = window.location.origin + "/products/" + deepLinkUrl;
    
    let carousalImages = productDetail.product
        ? [productDetail.product.primaryImageSrc]
        : [];
        if (
            productDetail.product &&
            productDetail.product.secondaryImageSrc
          ) {
            carousalImages = [
              ...carousalImages,
              ...productDetail.product.secondaryImageSrc,
            ];
          }

    const ImageDesc =
        productDetail && productDetail.product
        ? productDetail.product.description
        : "";

    const imgSrc =
        productDetail && productDetail.product
        ? productDetail.product.primaryImageSrc.imageSrc
        : "";

        return(
        <div >
         <MetaData
          title={ImageTitle}
          description={ellipSize(ImageDesc, 50)}
          ogUrl={fullOgUrl}
          ogImageUrl={imgSrc}
        />
         <span
           style={{
                fontSize: "2.7vh",
                fontWeight: "bold",
                color: "#4A4A4A",
                 }}
          >                   
          {productDetail.product.title}
         </span>  

        <div className={classes.actionContainer}>
        <ClickToCopy text={productDetail.product.description} />
        {carousalImages && carousalImages.length > 0 && (
        <DownloadImages images={carousalImages} />
        )}
        {/* <button
        onClick={(e) => this.shareSubmit(e)}
        className={`${classes.whatsApp} btn btn-secondary`}
        >
        <WhatsAppIcon />
        Share
        </button> */}
          {/* <img
    src={require("../../assets/images/Whatsapp-Logo.svg")}
    alt="whatsapp"
    style={{
      height: "3vh",
      width: "auto",
      cursor: "pointer",
      marginRight: "2px",
      background: "#FF4343",
      color: "#fff",
    }}
  /> */}

      </div>
    </div>
    )
};
}; export default withStyles(styles) (ShareCopyDownload);
