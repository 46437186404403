import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@material-ui/core/MobileStepper";
import { makeStyles } from "@material-ui/core/styles";
import { autoPlay } from "react-swipeable-views-utils";
import {
  FETCHED_PRODUCT_CATEGORIES,
  FETCHING_PRODUCT_CATEGORIES,
} from "../../actions/actionTypes";
import { getVendorsList } from "../../lib/api";
import "./ProductCategoriesSection.scss";



export default ({ isLoggedIn }) => {



  const useStyles = makeStyles((theme) => ({
    autoPlayContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    imgStyle: {
      height: "21vh",
      width: "100%",
      objectFit: "contain",
      borderRadius: "10px",
    },
    productHomeContainer: {
      height: window.innerHeight - 65,
      marginTop: "0vh",
      paddingLeft: "6px",
      paddingRight: "6px",
      paddingTop: "10px",
      paddingBottom: "10px",
      marginLeft: "15px",
      marginRight: "15px",
    },
    mobileStepper: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    }
  }));

  const classes = useStyles();


  const [activeStep, setActiveStep] = useState(0);



  const dispatch = useDispatch();
  const history = useHistory();
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const categories = useSelector((state) => state.products.productCategories);
  // const [categoriesLoading, setCategoriesLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: FETCHING_PRODUCT_CATEGORIES });


    getVendorsList().then((res) =>{
      const categories = res.data;

      // setCategoriesLoading(false);
      dispatch({
        type: FETCHED_PRODUCT_CATEGORIES,
        payload: categories,
      });
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStepChange = (activeStep) => {

    setActiveStep(activeStep);
  };

  const handleCategoryClick = (e, item) => {
    e.preventDefault();
    if (isLoggedIn) {
      history.push(`/products/?category=${item.name}`, item.id);
    }
  };

  
  return (

      <div>


          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {categories &&
            categories.length && 
            categories ? (
              categories
                .slice(0, 10)
                .map((step, index) => (
                  <div
                    className={classes.autoPlayContainer}
                    key={index}
                    onClick={(e) => handleCategoryClick(e, step)}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <img
                        className={classes.imgStyle}
                        src={step.logo}
                        alt={step.logo}
                      />
                    ) : null}
                  </div>
                ))
            ) : (
              <div></div>
            )}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={10}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
          />

    </div>
  );
};




