import React from "react";
import "./HomeCarousal.scss";
import { useSelector } from "react-redux";

export default () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.user);

  return (
    <div
      className={
        "container-fluid section-container " + (!isLoggedIn ? "bg-image" : "")
      }
    >
      {isLoggedIn ? (
        ""
      ) : (
        <div className="row" style={{ paddingTop: "100px" }}>
          <div className="col-2"></div>
          <div className="col-8 logo-section">
            <img
              className="logo-img"
              src={require("../../assets/images/Sheroes-Logo.svg")}
              alt="sheco"
              height="30%"
            />
          </div>
          <div className="col-2"></div>
        </div>
      )}
      {isLoggedIn ? (
        ""
      ) : (
        <div>
          <div className="text-styling">
            <div>OF The Women</div>
          </div>
          <div className="text-styling">
            <div>BY The Women</div>
          </div>
          <div className="text-styling">
            <div>FOR The Women</div>
          </div>
        </div>
      )}
      {isLoggedIn ? (
        <div className="text-center logged-in-home-section">
          <p>
            Welcome <span className="user-credit">{userData.firstName}</span>
            <span> to SHECO</span>
          </p>
        </div>
      ) : (
        <div>
          <div
            style={{
              marginTop: "20px",
              paddingBottom: "10px",
              fontSize: "18px",
              lineHeight: "23px",
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Become a
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              <b>FINANCIALLY INDEPENDENT WOMEN</b>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              with Sheco Coselling Network!
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../assets/images/Sheroes-group.png")}
              alt="sheroes-group"
            />
          </div>
        </div>
      )}
    </div>
  );
};
