import React, { useEffect, useState } from "react";
import { getProducts } from "../../lib/api";
import { Button } from "@material-ui/core";
export default function ProductTagCarousel(props) {
  const [details, setDetails] = useState(props.details);

  const [productList] = useState([]);
  const [currentStart, setCurrentStart] = useState(10);
  useEffect(() => {
    fetchData(0, 10);
    // eslint-disable-next-line
  }, []);

  const fetchData = (start, limit) => {
    getProducts({
      tag: details.slug,
      start: start,
      limit: limit,
    }).then((response) => {
      let responseData = response.data.products;
      productList.push(...responseData);
      
      setDetails({
        ...details,
        title: `${details.title}`,
      });
    });
  };
  function handleloadMore(start, limit) {
        setCurrentStart(currentStart + limit);
    fetchData(currentStart, limit)
  }

  return (
    <>
    {productList && productList.length > 0 ?  
    <div className="pt25 pb25" align="center">
      {/* {JSON.stringify(details)} */}
      <div className="mb25 section-title-container">
        <div className="section-title gray29">{details.title}</div>
      </div>
      <div className="similarProduct">
        {productList &&
          productList?.map((data, index) => (
            <div
              className="similarProductListItem"
              onClick={(e) => {
                e.preventDefault();

                window.location.replace(`/products/${data.id}`);
              }}
              key={index}
            >
              <img src={`${data.primaryImageSrc.imageSrc}`} alt={data.title} />
            </div>
          ))}
        {productList.length > 9 ? 
        <Button 
        color = "primary"
        variant = "text" 
        onClick={() => handleloadMore(0, 10)}
        >
          &#x2794;
        </Button>:""}
      </div>
    </div>
    : null }
    </>
  );
}
