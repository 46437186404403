import { USER_DATA_KEY_IN_STORAGE } from "../constants/global";
import {
  USER_LOADED,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  SIGNOUT_SUCCESS,
  OPEN_SEARCH_MODAL,
  CLOSE_SEARCH_MODAL,
  FETCH_HOME_PAGE_DATA,
  FETCHED_HOME_DATA,
  FETCH_PRODUCT_PAGE_DATA,
  FETCHED_PRODUCT_DATA,
} from "./actionTypes";

export const loadUser = (userToken) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADED,
    payload: userToken,
  });
};

export const login = () => (dispatch, getState) => {
  try {
    const userToken = "token123";
    console.log("api call response...", userToken);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: userToken,
    });

    localStorage.setItem("userToken", userToken);
  } catch (e) {
    console.log(e);
  }
};

export const register = () => (dispatch, getState) => {
  try {
    const userToken = "token123";

    dispatch({
      type: REGISTER_SUCCESS,
      payload: userToken,
    });

    localStorage.setItem("userToken", userToken);
  } catch (e) {
    console.log(e);
  }
};

export const signout = () => (dispatch, getState) => {
  try {
    // just remove the token from localStorage
    dispatch({
      type: SIGNOUT_SUCCESS,
    });

    localStorage.removeItem(USER_DATA_KEY_IN_STORAGE);
  } catch (e) {
    console.log(e);
  }
};

export const searchModalOpen = () => (dispatch, getState) => {
  dispatch({
    type: OPEN_SEARCH_MODAL,
    payload: true,
  });
};

export const searchModalClose = () => (dispatch, getState) => {
  dispatch({
    type: CLOSE_SEARCH_MODAL,
    payload: true,
  });
};

export const fetchHomePageData = () => (dispatch, payload) => {
  dispatch({
    type: FETCH_HOME_PAGE_DATA,
    payload: payload,
  });
};

export const fetchedHomeData = () => (dispatch, payload) => {
  dispatch({
    type: FETCHED_HOME_DATA,
    payload: payload,
  });
};
export const fetchProductPageData = () => (dispatch, payload) => {
  dispatch({
    type: FETCH_PRODUCT_PAGE_DATA,
    payload: payload,
  });
};
export const fetchedProductData = () => (dispatch, payload) => {
  dispatch({
    type: FETCHED_PRODUCT_DATA,
    payload: payload,
  });
};
export const loading = (bool) => {
  return bool
    ? {
        type: "SHOW_LOADER",
        data: bool,
      }
    : {
        type: "HIDE_LOADER",
        data: bool,
      };
};
