import React, { Component } from "react";
class MoreFromVendor extends Component{
render(){
      const {
        similarProductsList,
      }=this.props;
        return( 
            <>           
            {similarProductsList.length > 0 ? (
                <div style={{ margin: "14px 0px 14px 22px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "2.2vh",
                        fontWeight: "bold",
                        color: "#4A4A4A",
                      }}
                    >
                    More from <span style={{ color: "#FF4343" }}> {similarProductsList[0].vendorName} </span>
                    </span>
                    <span
                      style={{ marginRight: "22px", color: "red" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/products/?category=${similarProductsList[0].vendorName}`
                        );
                      }}
                    >
                      View All
                    </span>
                  </div>
                  <br/>
                  <div className="similarProduct">
                    {similarProductsList.map((data, index) => (
                      <div
                        className="similarProductListItem"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(`/products/${data.id}`);
                          window.location.reload(true);
                        }}
                        key={index}
                      >
                        <img
                          src={`${data.primaryImageSrc.imageSrc}`}
                          alt={data.title}
                        />
                      </div>
                    ))}
                  <br/>
                  <br/>
                  <br/>
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </>
        )
    }
};export default MoreFromVendor;