import React from "react";
import "./CommunityCard.scss";

const CommunityCard = ({ id, title, subTitle, img, numMembers, introText }) => {
  return (
    <div className="community-card shadow mb-3">
      <div className="card-top-section row">
        <div className="col-3">
          <div className="card-image"></div>
        </div>
        <div className="col-9">
          <div className="card-metadata">
            <p className="bold mb-0">{title}</p>
            <p className="font-14 mb-0 light-grey">{subTitle}</p>
            <p className="font-14 mb-0 light-grey">{`${numMembers} Members`}</p>
          </div>
        </div>
      </div>
      <div className="card-info-section">
        <p className="text-left font-14">{introText}</p>
      </div>
    </div>
  );
};

export default CommunityCard;
