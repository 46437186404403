/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import { white } from "../../colors/color";
import "./singleProductCopy.css";
import {
  getProductDetail,
  getCartDetail,
} from "../../lib/api";
import {
  getProducts,
  getAuthToken,
  registerUserEvent,
} from "../../lib/api";
import Loader from "../../components/loader";
import MetaData from "../../components/common/metaData";
import { ellipSize } from "../../utils/textUtil";
import AddToCart from "./AddToCart";
import ShareCopyDownload from "./ShareCopyDownload";
import Prices from "./Prices";
import CarousalImages from "./CarousalImages";
import MoreFromVendor from "./MoreFromVendor";
import UpdateQuantityAndDescription from "./UpdateQuantityAndDescription";
const styles = {
  singleProductMainBody: {
    height: window.innerHeight - 65,
    overflowY: "auto",
    paddingBottom: "15vh",
  },
  title4VHText: { fontSize: "4vh", textAlign: "center" },
  footerContainer: {
    height: "16vh",
    background: "#FF4343",
    position: "fixed",
    bottom: "0px",
    width: "100%",
    borderRadius: "12px 12px 0px 0px",
  },
  footerContainerChild: {
    padding: "26px 46px 0px 46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  joinTheCosellerContainer: (cosellClicked) => ({
    height: cosellClicked ? "6vh" : "14vh",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    bottom: "16vh",
    width: "100%",
    background: white,
  }),
  joinCosellerChild: (cosellClicked) => ({
    padding: cosellClicked ? "0px 30px" : "4px 30px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  }),
  joinTheCosellertext: {
    fontSize: "2.6vh",
    fontWeight: "bold",
    color: "#FF4343",
  },
  expandLessContainer: {
    width: "26px",
    height: "26px",
    background: "#FF4343",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: white,
  },
  becomeCosellerContainer: {
    padding: "0px 30px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  becomeCosellerTextDiv: {
    fontSize: "2.4vh",
    display: "flex",
    flexDirection: "column",
  },
  topImageContainer: {
    position: "relative",
    background: "#a9a9a966",
    height: "30vh",
    padding: "0px 20p",
    marginLeft: "22px",
    marginRight: "22px",
    marginTop: "80px",
  },

  productTitleContainer: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "4vh",
    margin: "10px 22px",
  },
  sizeSpan: {
    width: "50%",
    background: "#9e9e9e70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sizeSpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  colourSpan: {
    width: "50%",
    background: "#ffc10769",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    borderRadius: "4px",
  },
  colourSpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  quantitySpanLeft: {
    width: "20%",
    background: "#9E9E9E",
    borderRadius: "4px 1px 1px 4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  quantitySpanMiddle: {
    width: "30%",
    background: "#9e9e9e70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quantitySpanRight: {
    width: "20%",
    background: "#F44336",
    borderRadius: "1px 4px 4px 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
};

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageActiveStep: 0,
      productDetail: {},
      mediaStorage: {
        images: {},
        videos: {},
      },
      primaryImage: {
        id: "",
        mediaSrc: "",
        variantIds: [],
      },
      defaultVariant: { variantNameValue: { title: "Default Title" } },
      variantStorage: {},
      variantOptionTypeOptionValuesMapping: {},
      selectedVariantOptionTypes: [],
      selectedOptions: {},
      selectedVariant: {},
      productQuantity: 1,
      productTotalPrice: 0,
      similarProductsList: [],
      primaryCategory: {},
      cartItems: [],
      productExistingInCart: false,
      isFetchingData: true,
      loggedOut: false,
      selectedVariantArray: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (!getAuthToken()) {
      this.setState((state) => ({
        ...state,
        loggedOut: true,
        isFetchingData: false,
      }));
    } else {
      this.fetchData(id);
    }
  }

  getUserId() {
    let { userToken } = this.props.auth;
    userToken = userToken.split(".");
    let userData = JSON.parse(atob(userToken[1]));
    const { userId } = userData;
    return userId;
  }

  registerUserEventClick() {

    const  shoptypeData  = this.state.productDetail.product;

    const payload = {
      deviceId: this.props.auth.deviceId,
      action: "product_view",
      productId: shoptypeData.id,
      userId: this.getUserId(),
      referer: window.location.href,
      url: window.location.href,
    };
    registerUserEvent(payload, true).then((res) => {
      // console.log(res, "register res..");
    });
  }

  getVariantFromSelectedOption = () => {
    // generate the key here and search it from variantStorage
    let variantNameArray = [];
    let variantName = "";
    let {
      selectedOptions,
      defaultVariant,
      variantOptionTypeOptionValuesMapping,
      variantStorage,
    } = this.state;
    let selectedVariant = {};
    const selectedVariantOptionTypes = Object.keys(
      variantOptionTypeOptionValuesMapping
    );
    if (selectedVariantOptionTypes && selectedVariantOptionTypes.length > 0) {
      selectedVariantOptionTypes.forEach((optionType) => {
        if (selectedOptions[optionType]) {
          variantNameArray.push(selectedOptions[optionType]);
        }
      });
      variantName = variantNameArray.join("/");
      selectedVariant = variantStorage[variantName];
    } else {
      selectedVariant = defaultVariant;
    }
    this.setState(
      (state) => ({
        ...state,
        selectedVariant: {
          ...state.selectedVariant,
          ...selectedVariant,
        },
      }),
      () => {
        // console.log(this.state.selectedVariant, variantName);
        // based on selectedVariantId and productId, see if product existing in cart
        const variantId = this.state.selectedVariant.id;
        const productId = this.state.productDetail.product.id;
        const itemMatched = this.state.cartItems.filter((item) => {
          return item.variantId === variantId && item.productId === productId;
        });

        const productQuantityData =
          itemMatched.length > 0
            ? {
                productExistingInCart: true,
                productQuantity: parseInt(itemMatched[0].orderQuantity),
              }
            : {
                productExistingInCart: false,
              };
        this.setState(
          (state) => ({
            ...state,
            ...productQuantityData,
          }),
          () => this.handleQuantityChange(this.state.productQuantity)
        );
      }
    );
  };

  filterSelected = ({ selectedFilters }) => {
    this.setState(
      (state) => ({
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          ...selectedFilters,
        },
      }),
      () => {
        console.log(this.state, "filter select state...");
        this.getVariantFromSelectedOption();
      }
    );
  };

  handleQuantityChange = (qty) => {
    if (this.state.selectedVariant.discountedPrice) {
      let total = Number(this.state.selectedVariant.discountedPrice) * qty;
      this.setState((state) => ({
        ...state,
        productQuantity: qty,
        productTotalPrice: total,
      }));
    }
  };

  fetchData = async (id) => {
    try {
      const response = await getProductDetail(id);
      const cartResponse = await getCartDetail();
      const productDetail = response.data;
      // console.log(response.data.product.vendor.id)
      // if (
      //   response.data.product_categories &&
      //   response.data.product_categories.length
      // ) {
      //   const primaryCategory = response.data.product_categories[0];
      //   this.getSimilarProducts(primaryCategory);
      // }
      this.getSimilarProducts(response.data.product.vendor.id);
      this.setState(
        (state) => ({
          ...state,
          productDetail: {
            ...state.productDetail,
            ...productDetail,
          },
          cartItems:
            cartResponse.data && cartResponse.data.items
              ? cartResponse.data.items
              : [],
          isFetchingData: false,
        }),
        () => {
          let { productDetail } = this.state;
          let shoptypeData = productDetail.product;

          this.registerUserEventClick();

          // selectedOptions set to first value in the options list
          let { options, variants } = shoptypeData;
          let obj = {};
            options && 
            options.forEach((item) => {
            const key = item.name;
            const value = item.values[0];
            obj[key] = value;
            });
          
          this.setState({
            selectedOptions: { ...obj },
          });

          // selectedOptions end

          this.pushFetchedMediaDataToMediaStorage(
            shoptypeData.primaryImageSrc || {},
            shoptypeData.secondaryImageSrc || [],
            shoptypeData.primaryVideoSrc || {}
          );

          this.pushFetchedVariantDataToVariantStorage(
            variants || [],
            options || []
          );
        }
      );
    } catch (err) {
      this.setState((state) => ({
        ...state,
        isFetchingData: false,
      }));
      console.log(err);
    }
    // getProductDetail(id).then((response) => {
    //   const productDetail = response.data;
    //   this.setState(
    //     {
    //       ...this.state,
    //       productDetail: {
    //         ...this.state.productDetail,
    //         ...productDetail,
    //       },
    //     },
    //     () => {
    //       let { productDetail } = this.state;
    //       let { shoptypeData: fetchedProductDetails } = productDetail;

    //       this.pushFetchedMediaDataToMediaStorage(
    //         fetchedProductDetails.primaryImageSrc || {},
    //         fetchedProductDetails.secondaryImageSrc || [],
    //         fetchedProductDetails.primaryVideoSrc || {}
    //       );

    //       this.pushFetchedVariantDataToVariantStorage(
    //         fetchedProductDetails.variants || [],
    //         fetchedProductDetails.options || []
    //       );
    //     }
    //   );
    // });
  };

  pushFetchedMediaDataToMediaStorage = (
    primaryImageElement,
    secondaryImageElements
  ) => {
    let { mediaStorage, primaryImage } = this.state;

    if (!isEmpty(primaryImageElement)) {
      mediaStorage.images[primaryImageElement.id] = {
        id: primaryImageElement.id,
        mediaSrc: primaryImageElement.imageSrc,
        variantIds: primaryImageElement.variantIds || [],
        primary: true,
      };

      primaryImage = {
        id: primaryImageElement.id,
        mediaSrc: primaryImageElement.imageSrc,
        variantIds: primaryImageElement.variantIds || [],
      };
    }
    if (!isEmpty(secondaryImageElements)) {
      secondaryImageElements.forEach((imageElement) => {
        mediaStorage.images[imageElement.id] = {
          id: imageElement.id,
          mediaSrc: imageElement.imageSrc,
          variantIds: imageElement.variantIds || [],
        };
      });
    }
    this.setState({ mediaStorage, primaryImage });
  };

  // Variant List hashmap
  pushFetchedVariantDataToVariantStorage = (variants, options) => {
    let {
      defaultVariant,
      variantOptionTypeOptionValuesMapping,
      variantStorage,
    } = this.state;

    const selectedVariantId = variants.length > 0 ? variants[0].id : null;
    const variantArrays = [];

    options.forEach((option, index) => {
      variantOptionTypeOptionValuesMapping[option.name] = {
        values: option.values,
        index: index,
      };
      variantArrays.push(option.values);
    });

    // all combos available
    let variantNameArrays =
      variantArrays.length > 0 ? this.cartesian(variantArrays) : [];

    let variantNames = variantNameArrays.map((v) => v.join("/"));

    // if variants from backend are lesser than all combos available
    if (variants.length !== variantNameArrays.length) {
      const comboLength =
        variantArrays.length > 0 ? variantNameArrays[0].length : 0;

      // sort by descending specificity (no of speicifc attributes(variantNameValue))
      variants = variants.sort(
        (a, b) =>
          (b.variantNameValue ? Object.keys(b.variantNameValue).length : 0) -
          (a.variantNameValue ? Object.keys(a.variantNameValue).length : 0)
      );

      for (let variant of variants) {
        const variantNameValues = variant.variantNameValue
          ? Object.keys(variant.variantNameValue)
          : [];
        // if all attributes are specified
        if (variantNameValues.length === comboLength) {
          let variantArray = [];
          let variantName = "";
          Object.keys(variantOptionTypeOptionValuesMapping).forEach(
            (optionType) => {
              const index =
                variantOptionTypeOptionValuesMapping[optionType].index;
              variantArray[index] = variant.variantNameValue[optionType];
            }
          );

          variantName = variantArray.join("/");
          const variantNameIndex = variantNames.indexOf(variantName);
          variantStorage[variantName] = variant;
          variantNameArrays.splice(variantNameIndex, 1);
          variantNames.splice(variantNameIndex, 1);
          continue;
        }

        // if only some attributes are specified
        if (
          variantNameValues.length < comboLength &&
          variantNameValues.length > 0
        ) {
          Object.keys(variantOptionTypeOptionValuesMapping).forEach(
            (optionType) => {
              let matchingVariantArrays = [];
              const index =
                variantOptionTypeOptionValuesMapping[optionType].index;
              if (variant.variantNameValue[optionType]) {
                matchingVariantArrays = matchingVariantArrays.filter(
                  (v) => v[index] === variant.variantNameValue[optionType]
                );
                matchingVariantArrays = matchingVariantArrays.concat(
                  variantNameArrays.filter(
                    (v) => v[index] === variant.variantNameValue[optionType]
                  )
                );
              }

              matchingVariantArrays.forEach((variantArray) => {
                const variantName = variantArray.join("/");
                const variantNameIndex = variantNames.findIndex(
                  (v) => v === variantName
                );
                variantStorage[variantName] = variant;
                variantNameArrays.splice(variantNameIndex, 1);
                variantNames.splice(variantNameIndex, 1);
              });
            }
          );

          continue;
        }

        // if no attributes are specified
        if (variantNameValues.length === 0) {
          variantNameArrays.forEach((variantNameArray) => {
            const variantName = variantNameArray.join("/");
            variantStorage[variantName] = variant;
          });
          variantNameArrays = [];
          variantNames = [];
        }
      }

      // Add remaining variants as unavailable (quantity = -1)
      if (variantNameArrays.length > 0) {
        variantNames.forEach((v) => {
          variantStorage[v] = { quantity: -1 };
        });
      }
    } else {
      // if variants from backend are equal to all combos available
      variants.forEach((variant) => {
        let variantNameArray = [];
        let variantName = "";
        Object.keys(variantOptionTypeOptionValuesMapping).forEach(
          (optionType) => {
            if (variant.variantNameValue[optionType]) {
              variantNameArray.push(variant.variantNameValue[optionType]);
            }
          }
        );

        variantName = variantNameArray.join("/");
        variantStorage[variantName] = variant;
      });
    }
    this.setState(
      {
        defaultVariant,
        variantOptionTypeOptionValuesMapping,
        variantStorage,
        selectedVariantId,
      },
      () => {
        // Add the first variant name to the selectedVariant property
        this.getVariantFromSelectedOption();
      }
    );
  };

  cartesian = (args) => {
    var r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  };

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    if (this.state.productDetail.product?.id !== newId) {
      getProductDetail(newId);
    }
    return null;
  }

  getSimilarProducts = (slug) => {
    getProducts({
      categorySlug: slug,
      limit: 10,
    }).then((res) => {
      // console.log(res)
      const list = res.data.products.filter((p) => p.id !== this.state.productDetail.product.id);
      // console.log(list)
      this.setState({
        similarProductsList: list,
        // primaryCategory: primaryCategory,
      });
    });
  };

  

  render() {
    const { classes } = this.props;
    const {
      productDetail,
      productTotalPrice,
      isFetchingData,
      loggedOut,
    } = this.state;

    const imgSrc =
      productDetail && productDetail.product
        ? productDetail.product.primaryImageSrc.imageSrc
        : "";
    const ImageTitle =
      productDetail && productDetail.product
        ? productDetail.product.title
        : "";
    const ImageDesc =
      productDetail && productDetail.product
        ? productDetail.product.description
        : "";
    const deepLinkUrl =
      productDetail && productDetail.product ? productDetail.product._id : "";
    const fullOgUrl = window.location.origin + "/products/" + deepLinkUrl;
    return (
      <div className={classes.singleProductMainBody}>
        <MetaData
          title={ImageTitle}
          description={ellipSize(ImageDesc, 50)}
          ogUrl={fullOgUrl}
          ogImageUrl={imgSrc}
        />
        {loggedOut ? (
          <div className="container mt100 text-center">
            <img
              style={{ width: "100%" }}
              src={require("../../assets/images/error-icon.png")}
              alt="error"
            />
            <p
              style={{
                fontSize: "2.2vh",
                fontWeight: "bold",
                color: "#4A4A4A",
              }}
            >
              You are not authenticated!
            </p>
          </div>
         ) : isFetchingData ? (
          <div
            style={{
              marginTop: "50%",
            }}
          >
            <Loader />
          </div>
         ) : (
          <>   
          <CarousalImages
           productDetail = {this.state.productDetail}
           imageActiveStep = {this.state.imageActiveStep}
          ></CarousalImages>


            {productDetail && (
              <div>
                <div className = {classes.productTitleContainer}>
                  <ShareCopyDownload
                     productDetail = {this.state.productDetail}
                  >
                  </ShareCopyDownload>

                  <Prices
                   productDetail = {this.state.productDetail}
                  selectedVariant = {this.state.selectedVariant}
                  >
                  </Prices> 
                </div>
                  
                  <UpdateQuantityAndDescription
                  productDetail = {this.state.productDetail}
                  selectedVariant = {this.state.selectedVariant}
                  productQuantity = {this.state.productQuantity}
                  selectedOptions = {this.state.selectedOptions}
                  defaultVariant = {this.state.defaultVariant}
                  variantStorage = {this.state.variantStorage}
                  variantOptionTypeOptionValuesMapping = {this.state.variantOptionTypeOptionValuesMapping}
                  handleQuantityChange = {this.handleQuantityChange} 
                   filterSelected = {this.filterSelected}
                  >                 
                  </UpdateQuantityAndDescription>
      
                  <MoreFromVendor
                  similarProductsList = {this.state.similarProductsList}
                  history = {this.props.history}
                  >
                  </MoreFromVendor>
            
              </div>
            )}

            {/* <ShopCard />
              <ShopCard />
              <ShopCard />
              <div style={styles.joinTheCosellerContainer(this.state.cosellClicked)}>
                <div style={styles.joinCosellerChild(this.state.cosellClicked)}>
                  <span className={classes.joinTheCosellertext}>
                    Be a Sheco Partner on Sheroes!
                  </span>
                  {this.state.cosellClicked ? (
                    <div
                      className={classes.expandLessContainer}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ cosellClicked: false });
                      }}
                    >
                      <ExpandLess />
                    </div>
                  ) : (
                    <Cancel
                      style={{ color: "#FF4343" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ cosellClicked: true });
                      }}
                    />
                  )}
                </div>
                {this.state.cosellClicked ? null : (
                  <div className={classes.becomeCosellerContainer}>
                    <div className={classes.becomeCosellerTextDiv}>
                      <span>Become a coseller!</span>
                      <span>Earn as your shop </span>
                    </div>
                    <Fab
                      style={{
                        padding: "0px 24px",
                        height: "32px",
                        backgroundColor: "#FF4343",
                        color: white,
                        fontWeight: "bold",
                        width: "44%",
                      }}
                      variant="extended"
                      size="small"
                      color="primary"
                      aria-label="Add"
                    >
                      COSELL
                    </Fab>
                  </div>
                )}
              </div> */}
            <div className={classes.footerContainer}>
              <div className={classes.footerContainerChild}>
                {productDetail.product && (
                  <span
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {productDetail.product.currency} {productTotalPrice}
                  </span>
                )}
                <AddToCart
                 shoptypeData = {this.state.productDetail.product}
                 history = {this.props.history}
                 auth = {this.props.auth}
                 classes = {this.props.classes}
                 location = {this.props.location}
                 match = {this.props.match}
                 productDetail = {this.state.productDetail}
                 productQuantity = {this.state.productQuantity}
                 productExistingInCart = {this.state.productExistingInCart}
                 selectedVariant = {this.state.selectedVariant}
                 productId = {this.state.productId}              
                >
                </AddToCart>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  multiply = (num1, num2) => {
    return num1 * num2;
  };
  increaseProductQuantity = () => {
    this.setState((state) => ({
      productQuantity: state.productQuantity + 1,
    }));

    this.setState((state) => ({
      ...state,
      productTotalPrice:
        state.productDetail.product.variants[0].price *
        state.productQuantity,
    }));
  };
  decreaseProductQuantity = () => {
    this.setState((state) => ({
      productQuantity: state.productQuantity - 1,
    }));
    this.setState((state) => ({
      ...state,
      productTotalPrice: this.multiply(
        state.productDetail.product.variants[0].price,
        state.productQuantity
      ),
    }));
  };

}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(SingleProduct));