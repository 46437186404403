import axios from 'axios';
import { BASE_API_URL } from '../constants/api';
import Global from '../constants/global';

class HttpHelper {
  static baseApiUrl = BASE_API_URL;

  constructor() {
    this.http = axios;
    this.http.defaults.headers.common[Global.REQUEST_HEADER_KEYS.CONTENT_TYPE] =
      'application/json';
  }

  /**
   * @desc  Performs a get API call to server
   * @param options Request options
   */
  request(options) {
    if (options.customHeaders) {
      options.headers = options.customHeaders || {};
      delete options.customHeaders;
    }

    options.headers = {
      ...options.headers,
    };
    return this.http(options);
  }
}

const helper = new HttpHelper();
const HttpClient = {
  get: (url, params = null, customHeaders = null) =>
    helper.request({
      url,
      method: 'GET',
      params,
      customHeaders,
    }),
  post: (url, params = null, data = {}, headers = {}) =>
    helper.request({
      url,
      method: 'POST',
      data,
      params,
      headers,
    }),
  put: (url, params = null, data = {}, headers = {}) =>
    helper.request({
      url,
      method: 'PUT',
      data,
      params,
      headers,
    }),
  patch: (url, params = null, data = {}, headers = {}) =>
    helper.request({
      url,
      method: 'PATCH',
      data,
      params,
      headers,
    }),
  delete: (url, params = null, headers = {}) => 
    helper.request({
      url,
      method: 'delete',
      params,
      headers,
    })
};

export default HttpClient;
