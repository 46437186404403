import { 
  CLEAR_SEARCH_QUERY, 
  FETCHED_PRODUCTS_SEARCH_RESULTS, 
  FETCHED_PRODUCT_CATEGORIES, 
  FETCHING_PRODUCT_CATEGORIES, 
  SET_SEARCH_QUERY, 
  FETCH_PRODUCT_PAGE_DATA 
} from "../actions/actionTypes";
import { FETCHED_PRODUCT_DATA } from "../actions/actionTypes";

const initialState = {
  productSections: [],
  isFetching: false,
  productCategories: [],
  isFetchingProductCategories: false,
  productSearchResult: [],
  productSearchQuery: null,
}

export const productReducer = (state = initialState, action) => {
  switch (action.type) {

    // Fetch data for home page
    case FETCH_PRODUCT_PAGE_DATA:
      return {
        ...state,
        isFetching: true,
      }
    case FETCHED_PRODUCT_DATA:
      return {
        ...state,
        productSections: action.payload,
        isFetching: false,
      }

    case FETCHING_PRODUCT_CATEGORIES:
      return {
        ...state,
        isFetchingProductCategories: true,
      }

    case FETCHED_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: action.payload,
        isFetchingProductCategories: false,
      }

    case SET_SEARCH_QUERY: 
      return {
        ...state,
        productSearchQuery: action.payload,
        productSearchResult: [],
      }
    
    case CLEAR_SEARCH_QUERY:
      return {
        ...state,
        productSearchQuery: null,
        productSearchResult: [],
      }

    case FETCHED_PRODUCTS_SEARCH_RESULTS:
      return {
        ...state,
        productSearchResult: action.payload,
      }

    default:
      return state
  }
}