import React from 'react'
import { useSelector } from 'react-redux'
import ProductCard from '../../components/product-card'
import { addProductToMyShop } from '../../lib/api'

export default () => {

  const productSearchResult = useSelector(state => state.products.productSearchResult) 
  const searchQuery = useSelector(state => state.products.productSearchQuery)

  const handleAddProductToMyShop = (product) => {
    addProductToMyShop(product)
      .then((res) => {
        console.log("product MyShop response: ", res.data);
      })
      .catch((err) => console.log("product MyShop error: ", err));
  };

  return (
    <div>
      <div className="mt100 ml25 mr25">
        <div>
          <p style={{ fontSize: "20px" }}>
            Search results for <strong>"{searchQuery}"</strong>
          </p>
        </div>
        <div>
          {
            productSearchResult && productSearchResult.length
            ? productSearchResult.map((item, index) => (
                <ProductCard 
                  product={item} 
                  key={index}
                  addProductToMyShop={handleAddProductToMyShop}
                />
            ))
              : <p style={{ color: "#BFBFBF", marginTop: "150px", textAlign: "center" }}>
                <img style={{ height: "75px", width: "75px", filter: "invert(0.25)", marginBottom: "15px" }}
                  src={require("../../assets/images/Search.svg")}
                  alt="magnifier-img"
                />
                <p><b>No results found</b></p>
              </p>
          }
        </div>
      </div>
    </div>
  )
}