import React, { useEffect, useState } from "react";
import ProductCard from "../../components/product-card";
import TopNavbar from "../../components/top-navbar/TopNavbar";
import { getMyShopProducts, removeProductFromMyShop } from "../../lib/api";
import Loader from "../../components/loader";
import { toast } from "react-toastify";

export default () => {
  const [myProducts, setMyProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    
  }, []);

  const fetchData = () => {
    setLoading(true);
    getMyShopProducts().then((res) => {
      setLoading(false);
      setMyProducts(res.data);
    });
  };

  const handleRemoveProductFromMyShop = (product) => {
    removeProductFromMyShop(product)
      .then((res) => {
        toast.success("Item removed from your shop");
        fetchData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <TopNavbar />

      <div className="mt140 ml25 mr25">
        {loading ? (
          <div style={{ marginTop: "300px" }}>
            <Loader />
          </div>
        ) : (
          <div>
            {myProducts && myProducts.length > 0 ? (
              myProducts.map((product) => (
                <ProductCard
                  product={product}
                  key={product.id}
                  removeProductFromMyShop={handleRemoveProductFromMyShop}
                  isWishlisted={true}
                />
              ))
            ) : (
              <div>
                <p
                  style={{
                    color: "#BFBFBF",
                    marginTop: "300px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      height: "75px",
                      width: "75px",
                      filter: "invert(0.25)",
                      marginBottom: "15px",
                    }}
                    src={require("../../assets/images/Search.svg")}
                    alt="magnifier-img"
                  />
                  <p>
                    <b>No products found</b>
                  </p>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
