export const getUrlQueryParamsPrefix = url => url.indexOf('?') === -1 ? '?' : '&'

export const allSettled = function (promises) {
  let mappedPromises = promises.map((p) => {
    return p
      .then((value) => {
        return {
          status: 'fulfilled',
          value
        };
      })
      .catch((reason) => {
        return {
          status: 'rejected',
          reason
        };
      });
  });
  return Promise.all(mappedPromises);
};