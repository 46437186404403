/**
 * Injects dynamic scripts into index file
 * @param src Source path of the file
 * @param [id] Id for the script tag
 * @param [loadingStrategy] Loading strategy, i.e. async/defer
 * @param [type] Script type
 * @returns Promise
 */
export default function appendScript(src, id = null, loadingStrategy = 'async', type = 'text/javascript') {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.type = type;
    script.src = src;
    if (id) {
      script.id = id;
    }
    script[loadingStrategy] = true;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}
